import OrderDetailList from 'components/domain/mypage/order/detailList';
import Container from 'components/ui/layouts/container';
import { useParams } from 'react-router-dom';
import { useFetchUserOrderDetail } from 'services/queries/user/hooks/useFetchUser';

const UserSellDetailPage = () => {
  const { id } = useParams();
  const { data: order } = useFetchUserOrderDetail({ order_idx: id });
  return (
    <Container type="sub" title="판매 내역 상세">
      {order && <OrderDetailList data={order} />}
    </Container>
  );
};

export default UserSellDetailPage;
