import styled from 'styled-components';

const ProductListWrap = styled.div`
  display: flex;
  padding: 12px;
  .adm-checkbox {
    padding-right: 10px;
  }
  .adm-list-item {
    padding: 0;
  }
  .adm-list-item-content {
    border-top: 0;
  }
  .adm-list-item-content-main {
    padding: 0;
  }
  .adm-form .adm-form-footer {
    padding: 0;
  }
`;

const ProductList = styled.div`
  position: relative;
  padding-left: 102px;
  min-height: 90px;
  line-height: 1.2;
  h2 {
    font-size: 14px;
    margin-bottom: 4px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  > span {
    color: #9c9c9c;
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 90px;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 50px;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid rgb(244, 244, 250);
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 56px;
`;

const OrderBtn = styled.button`
  width: 100%;
  background-color: #0040dc;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  height: 100%;
`;

const TotalWrap = styled.div`
  padding: 10px 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  width: 100%;
  font-size: 14px;
  left: 0;
  border-top: 1px solid rgb(244, 244, 250);
  bottom: 105px;
  strong {
    font-size: 18px;
    font-weight: bold;
  }
`;

export { ProductListWrap, ProductList, BtnWrap, OrderBtn, TotalWrap };
