import { Button, Popup, Space, CheckList, Tag } from 'antd-mobile';
import { FilterOutline, SearchOutline } from 'antd-mobile-icons';
import { useState } from 'react';
import { LayoutCateModel } from 'services/queries/layout/layout';
import * as S from './styled';

interface FilterProps {
  categoryList: LayoutCateModel[];
  value: string[];
  onFinish: (value: string[]) => void;
}

const Filter = ({ value, categoryList, onFinish }: FilterProps) => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState<string[]>(value || []);

  return (
    <>
      <S.Wrapper>
        <Space>
          {selected.map((v: string) => (
            <Tag color="primary" key={v} style={{ fontSize: 12 }}>
              {categoryList.filter((list) => list.idx === Number(v))[0].name}
            </Tag>
          ))}
        </Space>
        <Button
          size="small"
          onClick={() => {
            setVisible(true);
          }}
        >
          <Space>
            <FilterOutline />
            <span>카테고리</span>
          </Space>
        </Button>
      </S.Wrapper>
      <S.Airticle />
      <Popup
        visible={visible}
        onMaskClick={() => {
          setSelected(value || []);
          setVisible(false);
        }}
        destroyOnClose
      >
        <S.SearchBarWrapper>
          <Button
            size="small"
            onClick={() => {
              onFinish(selected);
              setVisible(false);
            }}
          >
            <Space>
              <SearchOutline />
              <span>검색</span>
            </Space>
          </Button>
        </S.SearchBarWrapper>
        <S.CheckListWrapper>
          <S.CheckList
            multiple
            defaultValue={selected || []}
            onChange={(val) => {
              setSelected(val);
            }}
          >
            {categoryList.map((item: any) => (
              <CheckList.Item key={item.idx} value={String(item.idx)}>
                {item.name}
              </CheckList.Item>
            ))}
          </S.CheckList>
        </S.CheckListWrapper>
      </Popup>
    </>
  );
};

export default Filter;
