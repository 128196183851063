import {
  FileWrongOutline,
  HeartOutline,
  PayCircleOutline,
  SearchOutline,
  ShopbagOutline,
} from 'antd-mobile-icons';
import * as S from './styled';

interface NoListProps {
  status: 'default' | 'point' | 'search' | 'cart';
  title: string;
}

const NoList = ({ status, title }: NoListProps) => {
  const ErrorIcon = {
    default: <FileWrongOutline />,
    point: <PayCircleOutline />,
    search: <SearchOutline />,
    cart: <ShopbagOutline />,
    like: <HeartOutline />,
  };

  return (
    <S.Wrapper>
      {ErrorIcon[status]}
      <h3>{title}</h3>
    </S.Wrapper>
  );
};

export default NoList;
