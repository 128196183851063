import { useInfiniteQuery, useQuery } from 'react-query';
import { api, EndPoints } from 'services/api-config';
import {
  NoticeDetailModel,
  NoticeDetailParams,
  NoticeModel,
  NoticeParams,
} from '../notice';

export const useFetchNotice = ({ page, pageSize }: NoticeParams) =>
  useInfiniteQuery(
    ['notice'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<NoticeModel[]>(`${EndPoints.notice}`, {
        params: {
          page: pageParam,
          pageSize,
        },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchNoticeDetail = ({ notice_idx }: NoticeDetailParams) =>
  useQuery(
    ['noticeDetail', notice_idx],
    async () => {
      const { data } = await api.get<NoticeDetailModel>(
        `${EndPoints.notice}/${notice_idx}`,
      );
      return data;
    },
    {
      enabled: notice_idx !== undefined,
    },
  );
