import MainList from 'components/domain/main/list';
import SearchContainer from 'components/ui/layouts/container/search';
import useAccess from 'hooks/useAccess';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePostLike } from 'services/queries/like/hooks/usePostLike';
import { useFetchSearchContent } from 'services/queries/search/hooks/useFetchSearch';
import { LikeBody } from 'services/queries/like/like';
import { Toast } from 'antd-mobile';
import { useEffect } from 'react';

const SearchPage = () => {
  const navigator = useNavigate();
  const [query] = useSearchParams();
  const {
    data: searchList,
    fetchNextPage,
    refetch: refetchSearch,
    remove: removeSearch,
    hasNextPage,
  } = useFetchSearchContent({
    page: 1,
    pageSize: 20,
    category: '0',
    keyword: query.get('q') || undefined,
  });
  const { mutate: actionLike } = usePostLike('search');
  const { accessCheck } = useAccess({ isAuth: true });

  const onLike = (form: LikeBody, isLike: boolean) => {
    const { auth } = accessCheck(true);
    if (auth) {
      Toast.show({
        content: isLike
          ? '좋아요가 취소 되었습니다.'
          : '좋아요가 추가 되었습니다.',
        position: 'bottom',
      });

      actionLike(form);
    }
  };

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  useEffect(() => {
    if (query.get('q') !== '') refetchSearch();
    return () => {
      removeSearch();
    };
  }, [query]);

  const onSearchChange = (value: string) => {
    navigator(`/search/?q=${value}`, { replace: true });
  };

  return (
    <SearchContainer
      onSearch={onSearchChange}
      searchValue={query.get('q') || ''}
    >
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <MainList data={searchList} onLike={onLike} search={query.get('q')} />
      </InfiniteScroll>
    </SearchContainer>
  );
};

export default SearchPage;
