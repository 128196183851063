import styled from 'styled-components';

interface HeartProps {
  active: boolean;
}

const Wrapper = styled.div`
  display: block;
  position: relative;
`;

const Images = styled.div`
  position: relative;
  padding-bottom: 100%;
  width: 100%;
  margin-bottom: 0.25rem;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 4px;
  }
`;

const Heart = styled.div<HeartProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    color: ${({ active }) => (active ? '#ff4c30' : '#fff')};
  }
`;

const Title = styled.div`
  font-size: 16px;
  color: rgb(63, 62, 75);
  margin-top: 10px;
  line-height: 1.21;
  padding: 0px 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 0px 10px 0px 2px;
  font-size: 1rem;
`;

const BottomHeart = styled.div`
  font-size: 14px;
  line-height: 1.21;
  white-space: pre-wrap;
  color: rgb(114, 112, 127);
  margin-right: 12px;
  svg {
    margin-right: 4px;
  }
`;

const BottomTime = styled.div`
  flex: 1 1 0%;
  text-align: right;
  color: rgb(155, 153, 169);
`;

export { Wrapper, Images, Heart, Title, Bottom, BottomHeart, BottomTime };
