import { useInfiniteQuery, useQuery } from 'react-query';
import { api, EndPoints } from 'services/api-config';
import { QnaParams, QnaModel, QnaDetailModel, QnaDetailParams } from '../qna';

export const useFetchQna = ({ page, pageSize }: QnaParams) =>
  useInfiniteQuery(
    ['qna'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<QnaModel[]>(`${EndPoints.qna}`, {
        params: {
          page: pageParam,
          pageSize,
        },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchQnaDetail = ({ qna_idx }: QnaDetailParams) =>
  useQuery(
    ['qnaDetail', qna_idx],
    async () => {
      const { data } = await api.get<QnaDetailModel>(
        `${EndPoints.qna}/${qna_idx}`,
      );
      return data;
    },
    {
      enabled: qna_idx !== undefined,
    },
  );
