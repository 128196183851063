import styled, { css } from 'styled-components';

type NickNameType = 'good' | 'soso' | 'bad';

interface NickNameProps {
  type: NickNameType;
}

const getNickNameColor = (type: NickNameType) => {
  let color;

  switch (type) {
    case 'good':
      color = '#28a745';

      break;

    case 'soso':
      color = '#ffc107';

      break;

    case 'bad':
      color = '#dc3545';
      break;

    default:
      return null;
  }

  return css`
    color: ${color};
  `;
};

const State = styled.div<NickNameProps>`
  font-size: 15px;
  ${({ type }) => getNickNameColor(type)};
  svg {
    margin-right: 4px;
  }
`;

const StateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export { State, StateWrapper };
