import { Button, Form, Input, Space } from 'antd-mobile';
import FilterModal from 'components/ui/common/modal/filterModal';
import { LayoutBankModel } from 'services/queries/layout/layout';
import { UserPointRewardModel } from 'services/queries/user/user';
import { useEffect, useState } from 'react';
import * as S from './styled';

interface PontRewardProps {
  onFinish: (value: any) => void;
  bankList: LayoutBankModel[];
  pointData: UserPointRewardModel;
}

const PointReward = ({ onFinish, bankList, pointData }: PontRewardProps) => {
  const [form] = Form.useForm();
  const cache = Form.useWatch('cache', form);
  const commission = Number(pointData.commission) / 100;

  return (
    <Form
      form={form}
      initialValues={{
        cache: 0,
      }}
      onFinish={onFinish}
      footer={
        <Button block type="submit" color="primary" size="large">
          정산 신청하기
        </Button>
      }
    >
      <Form.Item
        name="bank"
        label="은행"
        rules={[{ required: true, message: '은행을 선택해주세요' }]}
      >
        <FilterModal
          filteredItems={bankList}
          placeholder="은행을 선택해주세요"
        />
      </Form.Item>
      <Form.Item
        name="account"
        label="계좌번호"
        rules={[{ required: true, message: '계좌번호를 입력해주세요' }]}
      >
        <Input placeholder="계좌번호를 입력해주세요" />
      </Form.Item>
      <S.PointWrap>
        <Space direction="vertical" justify="between" block>
          <Space justify="between" block>
            <div>사용가능 포인트</div>
            <div>{pointData.point.toLocaleString()}P</div>
          </Space>
          <Space justify="end" block>
            <Form.Item name="cache">
              <Input type="number" max={Number(pointData.point)} />
            </Form.Item>
            <Button
              block
              type="button"
              color="primary"
              size="small"
              onClick={() => {
                form.setFieldValue('cache', pointData.point);
              }}
              style={{ fontSize: 10 }}
            >
              전액사용
            </Button>
          </Space>
        </Space>
        <Space justify="between" align="center" block>
          <div>수수료</div>
          <div>{Math.floor(Number(cache) * commission).toLocaleString()}원</div>
        </Space>
        <Space justify="between" align="center" block>
          <div>최종 리워드 금액</div>
          <div>
            {(cache - Math.floor(Number(cache) * commission)).toLocaleString()}
            원
          </div>
        </Space>
      </S.PointWrap>
    </Form>
  );
};

export default PointReward;
