import styled, { css } from 'styled-components';

type NickNameType = 'good' | 'soso' | 'bad';

interface NickNameProps {
  type: NickNameType;
}

interface HeartBtnProps {
  active: boolean;
}

const getNickNameColor = (type: NickNameType) => {
  let color;

  switch (type) {
    case 'good':
      color = '#28a745';

      break;

    case 'soso':
      color = '#ffc107';

      break;

    case 'bad':
      color = '#dc3545';
      break;

    default:
      return null;
  }

  return css`
    color: ${color};
  `;
};

const MainImg = styled.div`
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
  }
`;

const MainInfo = styled.div`
  padding: 15px;
  background: #fff;
  margin-bottom: 10px;
  h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0px 15px;
  }
`;

const MainInfoSub = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    margin-right: 3px;
  }
`;

const NickName = styled.div<NickNameProps>`
  color: #9c9c9c;
  font-size: 15px;
  span {
    ${({ type }) => getNickNameColor(type)};
    margin-left: 8px;
  }
`;

const SubInfo = styled.div`
  display: flex;
  color: #9c9c9c;
  font-size: 15px;
  span {
    display: flex;
    align-items: center;
  }
  span:first-child::after {
    content: '';
    display: inline-block;
    margin: 0px 8px;
    width: 1px;
    height: 12px;
    line-height: 12px;
    vertical-align: bottom;
    background-color: rgb(238, 238, 238);
  }
`;

const ProductListWrap = styled.div`
  padding: 12px;
  .adm-list-item {
    padding: 0;
  }
  .adm-list-item-content {
    border-top: 0;
  }
  .adm-list-item-content-main {
    padding: 0;
  }
  .adm-form .adm-form-footer {
    padding: 0;
  }
`;

const ProductList = styled.div`
  position: relative;
  padding-left: 102px;
  min-height: 90px;
  line-height: 1.2;
  h2 {
    font-size: 14px;
    margin-bottom: 4px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2px;
  }
  > span {
    color: #9c9c9c;
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 90px;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid rgb(244, 244, 250);
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 56px;
`;

const BuyBtn = styled.button`
  width: 70%;
  background-color: #0040dc;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  height: 100%;
`;

const CartBtn = styled.button`
  font-size: 18px;
  font-weight: bold;
  color: #0040dc;
  background: #fff;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 30%;
  display: flex;
`;

const HeartBtn = styled.button<HeartBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active }) => (active ? '#ff4c30' : '#000')};
  font-size: 20px;
  background: #fff;
  padding: 0px 18px;
  border-right: 1px solid rgb(244, 244, 250);
  height: 100%;
`;

const TotalWrap = styled.div`
  padding: 10px 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  width: 100%;
  font-size: 14px;
  left: 0;
  border-top: 1px solid rgb(244, 244, 250);
  bottom: 56px;
  strong {
    font-size: 18px;
    font-weight: bold;
  }
`;

export {
  MainImg,
  MainInfo,
  MainInfoSub,
  NickName,
  SubInfo,
  ProductListWrap,
  ProductList,
  BtnWrap,
  BuyBtn,
  CartBtn,
  HeartBtn,
  TotalWrap,
};
