import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  display: block;
  bottom: 0px;
  z-index: 999;
  background: #fff;
  border-top: 1px solid #eee;
`;

export { Wrapper };
