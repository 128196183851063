import { useLocation, useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { TabBar } from 'antd-mobile';
import {
  FillinOutline,
  HeartOutline,
  ShopbagOutline,
  UserOutline,
} from 'antd-mobile-icons';
import Symbol from 'static/images/logo/symbol.png';
import SymbolOff from 'static/images/logo/symbol_off.png';
import useAccess from 'hooks/useAccess';
import * as S from './styled';

const Bottom: FC = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const { accessCheck } = useAccess({ isAuth: true });
  const { pathname } = location;

  const setRouteActive = (value: string) => {
    if (value !== '/main' && value !== '/like') {
      const { auth } = accessCheck(true);
      if (auth) {
        navigator(value);
      }
      return;
    }

    navigator(value);
  };

  const tabs = [
    {
      key: '/main',
      title: '메인',
      icon:
        pathname === '/main' ? (
          <img
            src={Symbol}
            alt="로고 심볼"
            width={24}
            style={{ float: 'left', marginTop: 3 }}
          />
        ) : (
          <img
            src={SymbolOff}
            alt="로고 심볼"
            width={24}
            style={{ float: 'left', marginTop: 3 }}
          />
        ),
    },
    {
      key: '/like',
      title: '좋아요',
      icon: <HeartOutline />,
    },
    {
      key: '/write/card',
      title: '컨텐츠 작성',
      icon: <FillinOutline />,
    },
    {
      key: '/cart',
      title: '장바구니',
      icon: <ShopbagOutline />,
    },
    {
      key: '/mypage',
      title: '마이페이지',
      icon: <UserOutline />,
    },
  ];

  return (
    <S.Wrapper>
      <TabBar activeKey={pathname} onChange={(value) => setRouteActive(value)}>
        {tabs.map((item) => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
    </S.Wrapper>
  );
};

export default Bottom;
