import { Toast } from 'antd-mobile';
import PointReward from 'components/domain/mypage/point/reward';
import Container from 'components/ui/layouts/container';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchLayoutBank } from 'services/queries/layout/hooks/useFetchLayout';
import { usePostReward } from 'services/queries/point/hooks/usePostPoint';
import { useFetchPointReward } from 'services/queries/user/hooks/useFetchUser';
import { useAlert } from 'store/alertStore';

const UserPointRewardPage = () => {
  const { data: bankList } = useFetchLayoutBank();
  const { data: pointData } = useFetchPointReward();
  const { mutate: postAction } = usePostReward();
  const navigator = useNavigate();
  const { showAlert } = useAlert();

  const onFinish = (value: any) => {
    if (pointData !== undefined) {
      if (value.cache < Number(pointData?.minPoint)) {
        showAlert({
          msg: `포인트 정산 최소 금액은 ${pointData.minPoint.toLocaleString()}원 입니다.`,
          title: '안내',
          btnNm: '확인',
        });
        return;
      }
      postAction(
        {
          bank: {
            idx: value.bank,
            account: value.account,
          },
          point: value.cache,
        },
        {
          onSuccess: () => {
            Toast.show({
              content: '정산 신청이 완료되었습니다.',
              position: 'bottom',
            });
            navigator('/mypage/point/history', { replace: true });
          },
        },
      );
    }
  };

  return (
    <Container type="sub" title="포인트 리워드">
      {bankList && pointData && (
        <PointReward
          onFinish={onFinish}
          bankList={bankList}
          pointData={pointData}
        />
      )}
    </Container>
  );
};

export default UserPointRewardPage;
