import create from 'zustand';

interface State {
  open: boolean;
  title: string;
  msg: string;
  callbackAction?: () => void;
  btnNm: string;
}

interface Alert {
  title: string;
  msg: string;
  btnNm: string;
}

interface Action {
  showAlert: (data: Alert, callbackAction?: () => void) => void;
  closeAlert: () => void;
}

const initialState: State = {
  open: false,
  title: '',
  msg: '',
  callbackAction: undefined,
  btnNm: '확인',
};

export const useAlert = create<State & Action>()((set) => ({
  ...initialState,
  showAlert: (data, callbackAction) => {
    set(() => ({ ...initialState, ...data, open: true, callbackAction }));
  },
  closeAlert: () => {
    set(() => ({ open: false, callbackAction: undefined }));
  },
}));
