import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
`;

const BtnWrapper = styled.div`
  width: 100%;
  padding: 12px;
  padding-bottom: 62px;
`;

const CheckListWrapper = styled.div`
  height: calc(100vh - 318px);
  overflow-y: scroll;
`;

const CheckListItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckListImgWrapper = styled.div`
  width: 40px;
  height: 40px;
  text-align: center;
`;

const CheckListTextWrapper = styled.div`
  padding-left: 10px;
`;

const CheckListActiveWrapper = styled.div`
  width: 100%;
  height: 140px;
  line-height: 140px;
  text-align: center;
  overflow-y: scroll;
  z-index: 10;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  font-size: 1rem;
  .adm-list {
    width: 100%;
  }
  .adm-list-item {
    background-color: #efefef;
    border-bottom: 1px solid #e2e2e2;
  }
  .adm-list-item:last-child {
    border-bottom: 0;
  }
`;

export {
  Wrapper,
  BtnWrapper,
  CheckListWrapper,
  CheckListImgWrapper,
  CheckListTextWrapper,
  CheckListItemWrapper,
  CheckListActiveWrapper,
};
