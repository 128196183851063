import { CheckList, Popup } from 'antd-mobile';
import { FC, useState } from 'react';
import * as S from './styled';

interface SelectFieldProps {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  filteredItems: any;
}

const FilterModal: FC<SelectFieldProps> = ({
  value = '',
  onChange,
  placeholder,
  filteredItems,
}) => {
  const [visible, setVisible] = useState(false);

  const triggerValue = (changedValue: Partial<string>) => {
    onChange?.(changedValue);
  };

  return (
    <>
      <S.Wrapper active={value === ''} onClick={() => setVisible(true)}>
        {value === ''
          ? placeholder
          : filteredItems.filter((itme: any) => itme.idx === Number(value))[0]
              .name}
      </S.Wrapper>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        destroyOnClose
      >
        <CheckList
          defaultValue={value ? [value] : []}
          onChange={(val) => {
            triggerValue(val[0]);
            setVisible(false);
          }}
        >
          {filteredItems.map((item: any) => (
            <CheckList.Item key={item.idx} value={item.idx}>
              {item.name}
            </CheckList.Item>
          ))}
        </CheckList>
      </Popup>
    </>
  );
};

export default FilterModal;
