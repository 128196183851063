import { api, EndPoints } from 'services/api-config';
import { queryClient } from 'services/queryClient';
import { useMutation } from 'react-query';
import { LikeBody, LikeResponse } from '../like';

export const usePostLike = (type: string) =>
  useMutation(
    async (form: LikeBody) => {
      const { data } = await api.post<LikeResponse>(`${EndPoints.like}`, form);
      return data;
    },
    {
      onSuccess: (data, { contentIdx }) => {
        if (type === 'main' || type === 'search') {
          const division = type === 'main' ? 'Content' : 'SearchContent';

          const prevContent = queryClient.getQueryData<any>(
            `infinite${division}`,
          );

          queryClient.removeQueries('infiniteLikeContent');

          if (prevContent) {
            const newContnets = prevContent.pages.map((contents: any) =>
              contents.map((content: any) => {
                if (content.idx === contentIdx) {
                  return {
                    ...content,
                    likeCount: data.likeCount,
                    isLike: !content.isLike,
                  };
                }
                return content;
              }),
            );

            queryClient.setQueriesData(`infinite${division}`, {
              ...prevContent,
              pages: newContnets,
            });
          }
        }

        if (type === 'like') {
          const prevContent = queryClient.getQueryData<any>(
            'infiniteLikeContent',
          );

          if (prevContent) {
            const newContnets =
              prevContent.pages.map((contents: any) =>
                contents.filter((content: any) => content.idx !== contentIdx),
              ) ?? [];

            queryClient.setQueriesData('infiniteLikeContent', {
              ...prevContent,
              pages: newContnets,
            });
          }
        }

        if (type === 'detail') {
          const prevContent = queryClient.getQueryData<any>([
            'contnetDetail',
            contentIdx,
          ]);

          if (prevContent) {
            queryClient.setQueriesData(['contnetDetail', contentIdx], {
              ...prevContent,
              isLike: !prevContent.isLike,
              likeCount: data.likeCount,
            });
          }
        }
      },
    },
  );
