import { Tabs } from 'antd-mobile';
import React from 'react';
import { FaqMenuModal } from 'services/queries/faq/faq';

interface FaqMenuProps {
  list: FaqMenuModal[];
  activeIdx: string;
  setTabIdx: (index: string) => void;
}

const FaqMenu = ({ list, activeIdx, setTabIdx }: FaqMenuProps) => (
  <Tabs
    defaultActiveKey={activeIdx}
    onChange={(v) => setTabIdx(v)}
    style={{ backgroundColor: '#fff' }}
  >
    {list.map((item) => (
      <Tabs.Tab title={item.name} key={item.idx} />
    ))}
  </Tabs>
);

export default FaqMenu;
