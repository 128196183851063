import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { setAxiosHeader } from 'services/api-config';
import { usePostLogin } from 'services/queries/user/hooks/usePostUser';

type AuthMiddlewareProps = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  const [cookies, setCookie] = useCookies([
    'logged_noAuth',
    'logged_auth',
    'firetoken',
  ]);

  const [token, setToken] = useState(false);
  const [fireToken, setFireToken] = useState<string | null>(null);
  const { data: user, mutate: loginAction } = usePostLogin();

  useEffect(() => {
    if ((window as any).ReactNativeWebView) {
      window.addEventListener('message', (e: any) => {
        if (JSON.parse(e.data).token.token !== undefined) {
          setFireToken(JSON.parse(e.data).token.token);
          setCookie(
            'firetoken',
            encodeURIComponent(JSON.parse(e.data).token.token),
          );
        }
      });

      document.addEventListener('message', (e: any) => {
        if (JSON.parse(e.data).token.token !== undefined) {
          setFireToken(JSON.parse(e.data).token.token);
          setCookie(
            'firetoken',
            encodeURIComponent(JSON.parse(e.data).token.token),
          );
        }
      });
    } else {
      setFireToken('string1234');
      setCookie('firetoken', 'string1234');
    }
  }, []);

  useEffect(() => {
    if (
      user === undefined &&
      cookies.logged_noAuth === undefined &&
      cookies.logged_auth === undefined &&
      fireToken !== null
    ) {
      loginAction({
        loginId: '',
        password: '',
        firebaseToken: encodeURIComponent(fireToken),
      });
    }
  }, [fireToken]);

  useEffect(() => {
    if (user !== undefined && cookies.logged_auth === undefined) {
      setCookie('logged_noAuth', user, {
        path: '/',
      });
    }
  }, [user]);

  useEffect(() => {
    if (cookies.logged_noAuth || cookies.logged_auth) {
      const { accessToken } = cookies.logged_noAuth || cookies.logged_auth;
      setToken(true);
      setAxiosHeader('authorization', `Bearer ${accessToken}`);
    }
  }, [cookies]);

  return token ? children : null;
};

export default AuthMiddleware;
