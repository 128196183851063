import { NavBar } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import * as S from '../styled';

interface SubHeaderProps {
  title: string;
}

const SubHeader = ({ title }: SubHeaderProps) => {
  const navigator = useNavigate();
  return (
    <S.Wrapper>
      <NavBar
        onBack={() => navigator(-1)}
        style={{
          '--height': '56px',
          '--border-bottom': '1px #eee solid',
        }}
      >
        {title}
      </NavBar>
    </S.Wrapper>
  );
};

export default SubHeader;
