import { ErrorBlock, Button, Space } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigator = useNavigate();

  return (
    <Space
      block
      direction="vertical"
      align="center"
      style={{ '--gap': '16px' }}
    >
      <ErrorBlock
        fullPage
        title="세션이 종료되었습니다."
        description="세션이 종료 되었습니다. 메인으로 이동 부탁드립니다."
      />
      <Button color="primary" onClick={() => navigator('/main')}>
        메인으로 이동하기
      </Button>
    </Space>
  );
};

export default ErrorPage;
