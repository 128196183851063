import { useInfiniteQuery, useQuery } from 'react-query';
import { api, EndPoints } from 'services/api-config';
import {
  UserBanResponse,
  UserContentParams,
  UserCotnetModel,
  UserOrderDetailParams,
  UserOrderDetailResponse,
  UserOrderModel,
  UserOrderParams,
  UserPointHistoryModel,
  UserPointHistoryParams,
  UserPointRewardModel,
  UserResponse,
} from '../user';

export const useFetchUser = () =>
  useQuery(['user'], async () => {
    const { data } = await api.get<UserResponse>(`${EndPoints.user}/me`);
    return data;
  });

export const useFetchUserBan = () =>
  useQuery(['user', 'ban'], async () => {
    const { data } = await api.get<UserBanResponse>(`${EndPoints.user}/me/ban`);
    return data;
  });

export const useFetchUserContnet = ({ page, pageSize }: UserContentParams) =>
  useInfiniteQuery(
    ['userContent'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<UserCotnetModel[]>(
        `${EndPoints.user}/me/content`,
        {
          params: {
            page: pageParam,
            pageSize,
          },
        },
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchUserOrder = ({ order, page, pageSize }: UserOrderParams) =>
  useInfiniteQuery(
    ['userOrder', order],
    async ({ pageParam = page }) => {
      const { data } = await api.get<UserOrderModel[]>(
        `${EndPoints.user}/me/order`,
        {
          params: {
            order,
            page: pageParam,
            pageSize,
          },
        },
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchUserOrderDetail = ({ order_idx }: UserOrderDetailParams) =>
  useQuery(
    ['userOrder', order_idx],
    async () => {
      const { data } = await api.get<UserOrderDetailResponse>(
        `${EndPoints.user}/me/order/${order_idx}`,
      );
      return data;
    },
    {
      enabled: order_idx !== undefined,
    },
  );

export const useFetchPointReward = () =>
  useQuery(['userPointReward'], async () => {
    const { data } = await api.get<UserPointRewardModel>(
      `${EndPoints.user}/me/point/reward`,
    );
    return data;
  });

export const useFetchPointHistory = ({
  startDate,
  endDate,
  page,
  pageSize,
}: UserPointHistoryParams) =>
  useInfiniteQuery(
    ['userPointHistory'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<UserPointHistoryModel[]>(
        `${EndPoints.user}/me/point/history`,
        {
          params: {
            startDate,
            endDate,
            page: pageParam,
            pageSize,
          },
        },
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );
