import { useQuery } from 'react-query';
import { api, EndPoints } from 'services/api-config';
import { LayoutCateModel, LayoutPointModel, LayoutBankModel } from '../layout';

export const useFetchLayoutCate = () =>
  useQuery(['categoryList'], async () => {
    const { data } = await api.get<LayoutCateModel[]>(
      `${EndPoints.layout}/category`,
    );
    return data;
  });

export const useFetchLayoutBank = () =>
  useQuery(['bankList'], async () => {
    const { data } = await api.get<LayoutBankModel[]>(
      `${EndPoints.layout}/bank`,
    );
    return data;
  });

export const useFetchLayoutPoint = () =>
  useQuery(['pointList'], async () => {
    const { data } = await api.get<LayoutPointModel[]>(
      `${EndPoints.layout}/point`,
    );
    return data;
  });
