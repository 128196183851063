import React from 'react';
import { InfiniteData } from 'react-query';
import { EventModel } from 'services/queries/event/event';
import * as S from './styled';

interface EventListProps {
  list: InfiniteData<EventModel[]> | undefined;
  onClick: (value: number) => void;
}

const EventList = ({ list, onClick }: EventListProps) => (
  <S.Wrapper>
    <ul>
      {list !== undefined &&
        list.pages.map((pageData) =>
          pageData.map((item) => (
            <li key={String(item.idx)} onClick={() => onClick(item.idx)}>
              <img src={item.imageUrl} alt={`이벤트 배너${item.idx}`} />
            </li>
          )),
        )}
    </ul>
  </S.Wrapper>
);

export default EventList;
