import { Radio, Form } from 'antd-mobile';
import { LayoutPointModel } from 'services/queries/layout/layout';
import * as S from './styled';

interface PointChargeListProps {
  data: LayoutPointModel[];
  onFinish: (value: any) => void;
}

const PontChargeList = ({ data, onFinish }: PointChargeListProps) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      name="form"
      initialValues={{
        charge: 1,
      }}
      onFinish={onFinish}
      footer={
        <S.BtnWrap>
          <S.OrderBtn>충전하기</S.OrderBtn>
        </S.BtnWrap>
      }
    >
      <S.PointTable>
        <thead>
          <tr>
            <th>선택</th>
            <th>금액</th>
            <th>포인트</th>
          </tr>
        </thead>
        <tbody>
          <Form.Item
            name="charge"
            noStyle
            style={{
              borderTop: 0,
            }}
          >
            <Radio.Group>
              {data.map((v) => (
                <tr
                  key={v.idx}
                  onClick={() => {
                    form.setFieldValue('charge', v.idx);
                  }}
                >
                  <td>
                    <Radio value={v.idx} />
                  </td>
                  <td>{Number(v.name).toLocaleString()}원</td>
                  <td>{Number(v.point).toLocaleString()}P</td>
                </tr>
              ))}
            </Radio.Group>
          </Form.Item>
        </tbody>
      </S.PointTable>
    </Form>
  );
};

export default PontChargeList;
