import styled from 'styled-components';

const Wrapper = styled.div`
  flex-flow: row wrap;
  -webkit-box-orient: horizontal;
  width: 100%;
  padding: 0px 0.5rem;
  margin-top: 10px;
  display: flex;
  > div {
    display: block;
    margin-bottom: 18px;
    width: calc((100% - 8px) / 2);
    margin-right: 0.5rem;
  }
  > div:nth-child(2n) {
    margin-right: 0;
  }
`;

export { Wrapper };
