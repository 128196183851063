import { Tag } from 'antd-mobile';
import moment from 'moment';
import { QnaDetailModel } from 'services/queries/qna/qna';
import * as S from './styled';

interface QnaDetailProps {
  data: QnaDetailModel;
}

const QnaDetail = ({ data }: QnaDetailProps) => (
  <>
    <S.Title>
      {data.title} <span>{moment(data.createdAt).format('YYYY-MM-DD')}</span>
    </S.Title>
    <S.Contnet>{data.content}</S.Contnet>
    {data.answer && (
      <S.Contnet>
        <Tag color="#87d068" fill="outline">
          답변완료
        </Tag>
        <br />
        {data.answer}
      </S.Contnet>
    )}
  </>
);

export default QnaDetail;
