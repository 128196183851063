import PontChargeList from 'components/domain/mypage/point/charge/list';
import Container from 'components/ui/layouts/container';
import { useState } from 'react';
import { useFetchLayoutPoint } from 'services/queries/layout/hooks/useFetchLayout';

const UserPointChargePage = () => {
  const { data: point } = useFetchLayoutPoint();
  const [, setValue] = useState();
  const onFinish = (value: any) => {
    setValue(value);
  };
  return (
    <Container type="sub" title="포인트 충전">
      {point && <PontChargeList data={point} onFinish={onFinish} />}
    </Container>
  );
};

export default UserPointChargePage;
