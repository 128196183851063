import { api, EndPoints } from 'services/api-config';
import { useMutation } from 'react-query';
import { ContentAddBody, ContentAddBodyProduct } from '../content';

interface UsePostContentProductProps {
  content_idx: number;
  form: ContentAddBodyProduct;
}

export const usePostContent = () =>
  useMutation(async (form: ContentAddBody) => {
    const { data } = await api.post(`${EndPoints.content}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  });

export const usePostContentProduct = () =>
  useMutation(async ({ content_idx, form }: UsePostContentProductProps) => {
    const { data } = await api.post(
      `${EndPoints.content}/${content_idx}/product`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  });
