import OrderList from 'components/domain/mypage/order/list';
import Container from 'components/ui/layouts/container';
import InfiniteScroll from 'react-infinite-scroller';
import { useFetchUserOrder } from 'services/queries/user/hooks/useFetchUser';

const UserSellPage = () => {
  const {
    data: order,
    fetchNextPage,
    hasNextPage,
  } = useFetchUserOrder({ order: 'sell', page: 1, pageSize: 10 });

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  return (
    <Container type="sub" title="판매 내역">
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <OrderList data={order} status="sell" />
      </InfiniteScroll>
    </Container>
  );
};

export default UserSellPage;
