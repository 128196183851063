import { Toast } from 'antd-mobile';
import Login from 'components/domain/login';
import Container from 'components/ui/layouts/container';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { setAxiosHeader } from 'services/api-config';
import { usePostLogin } from 'services/queries/user/hooks/usePostUser';
import { useAlert } from 'store/alertStore';
import { queryClient } from 'services/queryClient';
import Footer from "../../components/ui/layouts/footer";

const LoginPage = () => {
  const [cookie, setCookie, removeCookie] = useCookies([
    'logged_noAuth',
    'logged_auth',
    'firetoken',
  ]);

  const { showAlert } = useAlert();
  const { data: user, mutate: loginAction } = usePostLogin();
  const navigator = useNavigate();

  useEffect(() => {
    if (user !== undefined) {
      const { accessToken } = user;
      setCookie('logged_auth', user, {
        path: '/',
        expires: new Date(Date.now() + user.accessTokenExpire),
      });
      removeCookie('logged_noAuth', { path: '/' });
      setAxiosHeader('authorization', `Bearer ${accessToken}`);

      Toast.show({
        content: '로그인 되었습니다.',
        position: 'bottom',
      });
      queryClient.clear();
      navigator('/main');
    }
  }, [user]);

  const onLogin = (values: any) => {
    loginAction(
      {
        loginId: values.loginId,
        password: values.password,
        firebaseToken: cookie.firetoken,
      },
      {
        onError: () => {
          showAlert(
            {
              title: '안내',
              msg: '아이디 혹은 비밀번호가 일치하지 않습니다.',
              btnNm: '확인',
            },
            () => {},
          );
        },
      },
    );
  };

  return (
    <Container type="sub" title="로그인">
      <Login onLogin={onLogin} />
      <Footer />
    </Container>
  );
};

export default LoginPage;
