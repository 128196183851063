import { Toast } from 'antd-mobile';
import Info from 'components/domain/info';
import Container from 'components/ui/layouts/container';
import { useNavigate } from 'react-router-dom';
import { useFetchUser } from 'services/queries/user/hooks/useFetchUser';
import { usePutInfo } from 'services/queries/user/hooks/usePostUser';
import { useAlert } from 'store/alertStore';

const MyInfoPage = () => {
  const { data: infoData } = useFetchUser();
  const { mutate: putAction } = usePutInfo();
  const navigator = useNavigate();
  const { showAlert } = useAlert();

  const onFinish = (values: any) => {
    if (values.password !== values.passwordCheck) {
      showAlert({
        msg: '비밀번호가 일치하기 않습니다.',
        title: '안내',
        btnNm: '확인',
      });
    }

    putAction(
      {
        nickName: values.nickName,
        address: values.address,
        addressDetail: values.addressDetail,
        zipcode: values.zipcode,
        mobile: values.mobile,
        password: values.password,
      },
      {
        onSuccess: () => {
          Toast.show({
            content: '정보 수정이 완료되었습니다.',
            position: 'bottom',
          });
          navigator('/mypage', { replace: true });
        },
      },
    );
  };
  return (
    <Container type="sub" title="내 정보 수정">
      {infoData && <Info onFinish={onFinish} infoData={infoData} />}
    </Container>
  );
};

export default MyInfoPage;
