import { ImageUploader, ImageUploadItem } from 'antd-mobile';
import { useState } from 'react';

interface ImgUploaderProps {
  value?: ImageUploadItem[];
  onChange?: (value: any) => void;
}

const ImgUploader = ({ value, onChange }: ImgUploaderProps) => {
  const [fileList, setFileList] = useState<ImageUploadItem[]>(value || []);

  const upload = async (file: File) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('file_type', file.type);

    return {
      url: URL.createObjectURL(file),
      file,
    };
  };

  return (
    <ImageUploader
      maxCount={1}
      value={fileList}
      onChange={(info) => {
        if (onChange) onChange(info);
        setFileList(info);
      }}
      upload={upload}
    />
  );
};

export default ImgUploader;
