import Join from 'components/domain/join';
import Container from 'components/ui/layouts/container';
import React from 'react';
import { usePostJoin } from 'services/queries/user/hooks/usePostUser';
import { Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'store/alertStore';
import moment from 'moment';

const SignUpPage = () => {
  const { mutate: joinAction } = usePostJoin();
  const navigator = useNavigate();
  const { showAlert } = useAlert();

  const onJoin = (values: any) => {
    if (values.password !== values.passwordCheck) {
      showAlert({
        msg: '비밀번호가 일치하기 않습니다.',
        title: '안내',
        btnNm: '확인',
      });

      return;
    }

    joinAction(
      {
        loginId: values.loginId,
        password: values.password,
        name: values.name,
        mobile: values.mobile,
        nickName: values.nickName,
        gender: values.gender.toString(),
        birthYear: moment(values.birthDay).format('YYYY'),
        birthDay: moment(values.birthDay).format('MMDD'),
      },
      {
        onSuccess: () => {
          Toast.show({
            content: '회원가입이 완료되었습니다.',
            position: 'bottom',
          });
          navigator('/login', { replace: true });
        },
      },
    );
  };

  return (
    <Container type="sub" title="회윈가입">
      <Join onFinish={onJoin} />
    </Container>
  );
};

export default SignUpPage;
