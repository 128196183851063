import PointHistoryList from 'components/domain/mypage/point/history/list';
import Container from 'components/ui/layouts/container';
import PointHistoryFilter from 'components/domain/mypage/point/history/filter';
import { useFetchPointHistory } from 'services/queries/user/hooks/useFetchUser';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { useEffect, useState } from 'react';
import { useAlert } from 'store/alertStore';

const UserPointHistoryPage = () => {
  const { showAlert } = useAlert();
  const [date, setDate] = useState({
    startDate: moment().subtract(7, 'days').format(),
    endDate: moment().format(),
  });
  const {
    data: history,
    refetch: refetchHistory,
    fetchNextPage,
    hasNextPage,
  } = useFetchPointHistory({
    startDate: date.startDate,
    endDate: date.endDate,
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    refetchHistory();
  }, [date]);

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  const onFinish = (value: any) => {
    if (moment(value.startDate).isBefore(moment(value.endDate))) {
      setDate({
        startDate: moment(value.startDate).format(),
        endDate: moment(value.endDate).format(),
      });
    } else {
      showAlert({
        msg: '종료일보다 시작일이 커서<br> 검색이 불가능 합니다.',
        title: '안내',
        btnNm: '확인',
      });
    }
  };

  return (
    <Container type="sub" title="포인트 내역">
      <PointHistoryFilter onFinish={onFinish} />
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <PointHistoryList data={history} />
      </InfiniteScroll>
    </Container>
  );
};

export default UserPointHistoryPage;
