import { QueryClient, QueryCache } from 'react-query';

const queryErrorHandler = (error: any) => {
  if (error.code === 'ERR_NETWORK') {
    window.location.replace('/error');
  }
};

export const queryCache = new QueryCache({});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
