import SubHeader from '../header/sub';
import Bottom from '../bottom';
import Header from '../header/main';
import * as S from './styled';

interface ContainerProps {
  title?: string;
  type: 'main' | 'sub' | 'subNoBottom' | 'content';
  children: React.ReactNode;
}
const Container = ({ title, type, children }: ContainerProps) => (
  <>
    {type === 'main' && <Header />}
    {title && <SubHeader title={title} />}
    <S.Wrapper type={type}>{children}</S.Wrapper>
    {type !== 'subNoBottom' && type !== 'content' && <Bottom />}
  </>
);

export default Container;
