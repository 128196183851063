import { Button, List, Tag } from 'antd-mobile';
import moment from 'moment';
import { InfiniteData } from 'react-query';
import { QnaModel } from 'services/queries/qna/qna';
import NoList from 'components/ui/common/noList';
import * as S from './styled';

interface QnaListProops {
  list: InfiniteData<QnaModel[]> | undefined;
  onClick: (value: number) => void;
  onClickWrite: () => void;
}

const QnaList = ({ list, onClick, onClickWrite }: QnaListProops) => (
  <List>
    <List.Item>
      <Button block color="primary" size="large" onClick={onClickWrite}>
        문의 작성하기
      </Button>
    </List.Item>
    {list === undefined || list.pages[0].length === 0 ? (
      <NoList title="1:1문의 내역이 없습니다." status="default" />
    ) : (
      list.pages.map((pageData) =>
        pageData.map((item) => (
          <List.Item
            onClick={() => onClick(item.idx)}
            title={
              <S.Title>
                {item.title}
                <S.Date>{moment(item.createdAt).format('YYYY-MM-DD')}</S.Date>
                <Tag
                  color={item.answeredAt === null ? '#ff6430' : '#87d068'}
                  fill="outline"
                >
                  {item.answeredAt === null ? '답변 ' : '답변 완료'}
                </Tag>
              </S.Title>
            }
          />
        )),
      )
    )}
  </List>
);

export default QnaList;
