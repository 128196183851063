import styled from 'styled-components';

interface ActiveProps {
  active: boolean;
}

const Wrapper = styled.div<ActiveProps>`
  width: 100%;
  font-size: 17px;
  color: ${({ active }) => (active ? '#ccc' : '#333')};
`;

export { Wrapper };
