import { NavBar, SearchBar } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from '../styled';

interface SearchHeaderProps {
  onSearch: (value: string) => void;
  value: string;
}

const SearchHeader = ({ onSearch, value }: SearchHeaderProps) => {
  const navigator = useNavigate();
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const onChagne = (data: any) => {
    setSearchValue(data);
  };

  return (
    <S.SearchWrapper>
      <NavBar
        onBack={() => navigator(-1)}
        style={{
          '--height': '56px',
          '--border-bottom': '1px #eee solid',
        }}
      >
        <SearchBar
          style={{ width: 'calc(100%)' }}
          placeholder="검색어를 입력하세요"
          onChange={onChagne}
          value={searchValue}
          onSearch={onSearch}
        />
      </NavBar>
    </S.SearchWrapper>
  );
};

export default SearchHeader;
