import React from 'react';
import { HeartOutline, HeartFill } from 'antd-mobile-icons';
import * as S from './styled';

interface CardProps {
  idx: number;
  title: string;
  time: string;
  like: number;
  imgUrl: string;
  isLike?: boolean;
  onClick?: (idx: number) => void;
  onLike?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    idx: number,
    isLike: boolean,
  ) => void;
}

const Card = ({
  idx,
  title,
  time,
  like,
  isLike,
  imgUrl,
  onLike,
  onClick,
}: CardProps) => (
  <S.Wrapper onClick={() => onClick && onClick(idx)}>
    <S.Images>
      {onLike && isLike !== undefined && (
        <S.Heart active={isLike} onClick={(e) => onLike(e, idx, isLike)}>
          {isLike ? <HeartFill /> : <HeartOutline />}
        </S.Heart>
      )}
      <img src={imgUrl} alt={`${title}상품 이미지`} />
    </S.Images>
    <S.Title>{title}</S.Title>
    <S.Bottom>
      <S.BottomHeart>
        <HeartOutline />
        {like}
      </S.BottomHeart>
      <S.BottomTime>{time}</S.BottomTime>
    </S.Bottom>
  </S.Wrapper>
);

export default Card;
