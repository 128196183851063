import React, { RefObject } from 'react';
import {
  Button,
  DatePicker,
  DatePickerRef,
  Form,
  Input,
  Radio,
  Space,
} from 'antd-mobile';
import moment from 'moment';

interface JoinProps {
  onFinish: (value: any) => void;
}

const Join = ({ onFinish }: JoinProps) => (
  <Form
    layout="horizontal"
    onFinish={onFinish}
    style={{
      borderTop: 0,
    }}
    footer={
      <Button block type="submit" color="primary" size="large">
        가입
      </Button>
    }
  >
    <Form.Item
      name="loginId"
      label="아이디"
      rules={[{ required: true, message: '아이디를 입력해주세요' }]}
    >
      <Input placeholder="아이디" />
    </Form.Item>
    <Form.Item
      name="password"
      label="비밀번호"
      rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
    >
      <Input placeholder="비밀번호" type="password" />
    </Form.Item>
    <Form.Item
      name="passwordCheck"
      label="비밀번호 확인"
      rules={[{ required: true, message: '비밀번호가 일치 하지 않습니다.' }]}
    >
      <Input placeholder="비밀번호 확인" type="password" />
    </Form.Item>
    <Form.Item
      name="name"
      label="이름"
      rules={[{ required: true, message: '이름을 입력해주세요' }]}
    >
      <Input placeholder="이름" />
    </Form.Item>
    <Form.Item
      name="mobile"
      label="휴대폰 번호"
      rules={[{ required: true, message: '휴대폰 번호를 입력해주세요' }]}
    >
      <Input placeholder="휴대폰 번호" type="tel" />
    </Form.Item>
    <Form.Item
      name="nickName"
      label="닉네임"
      rules={[{ required: true, message: '닉네임을 입력해주세요' }]}
    >
      <Input placeholder="닉네임" />
    </Form.Item>
    <Form.Item
      name="gender"
      label="성별"
      initialValue={0}
      rules={[{ required: true, message: '성별을 선택해주세요' }]}
    >
      <Radio.Group>
        <Space>
          <Radio value={1}>남성</Radio>
          <Radio value={2}>여성</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
    <Form.Item
      name="birthDay"
      label="생년월일"
      trigger="onConfirm"
      rules={[{ required: true, message: '생년월일을 선택해주세요' }]}
      onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
        datePickerRef.current?.open();
      }}
    >
      <DatePicker
        confirmText="확인"
        cancelText="취소"
        max={new Date(moment().format('YYYY-MM-DD'))}
        min={new Date(moment().subtract(100, 'years').format('YYYY-MM-DD'))}
      >
        {(value) => (value ? moment(value).format('YYYY-MM-DD') : '생년월일')}
      </DatePicker>
    </Form.Item>
  </Form>
);

export default Join;
