import Card from 'components/ui/common/card';
import { InfiniteData } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LikeBody } from 'services/queries/like/like';

import { getBeforeDate } from 'utils/date';
import NoList from 'components/ui/common/noList';
import * as S from './styled';

interface MainListProps {
  data: InfiniteData<any[]> | undefined;
  onLike: (form: LikeBody, isLike: boolean) => void;
  search?: string | null;
}

const MainList = ({ data, onLike, search }: MainListProps) => {
  const navigator = useNavigate();
  const onLikeAciton = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    idx: number,
    isLike: boolean,
  ) => {
    e.stopPropagation();
    onLike({ contentIdx: idx }, isLike);
  };

  const onClick = (idx: number) => {
    navigator(`/content/${idx}`);
  };

  return data !== undefined && data.pages[0].length === 0 ? (
    <NoList
      title={`${search ? `'${search}' 검색` : '검색'} 내역이 없습니다.`}
      status="search"
    />
  ) : (
    <S.Wrapper>
      {data !== undefined &&
        data.pages.map((pageData) =>
          pageData.map((card) => (
            <Card
              idx={card.idx}
              onClick={onClick}
              onLike={onLikeAciton}
              key={card.idx}
              isLike={card.isLike}
              title={card.title}
              time={getBeforeDate(card.createdAt)}
              like={card.likeCount}
              imgUrl={card.imageUrl}
            />
          )),
        )}
    </S.Wrapper>
  );
};

export default MainList;
