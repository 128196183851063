import React, { useState } from 'react';
import { Button, Form, Input, Popup } from 'antd-mobile';
import DaumPostcode from 'react-daum-postcode';

interface DataProps {
  nickName: string;
  address: string;
  addressDetail: string;
  zipcode: string;
  mobile: string;
}

interface InfoProps {
  onFinish: (value: any) => void;
  infoData: DataProps;
}

const Info = ({ onFinish, infoData }: InfoProps) => {
  const [modalState, setModalState] = useState(false);
  const [form] = Form.useForm();

  const onCompletePost = (data: any) => {
    setModalState(false);
    form.setFieldValue('zipcode', data.zonecode);
    form.setFieldValue('address', data.address);
  };

  const postCodeStyle = {
    height: '100%',
  };

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        initialValues={infoData}
        onFinish={onFinish}
        style={{
          borderTop: 0,
        }}
        footer={
          <Button block type="submit" color="primary" size="large">
            확인
          </Button>
        }
      >
        <Form.Item
          name="nickName"
          label="닉네임"
          rules={[{ required: true, message: '닉네임을 입력해주세요' }]}
        >
          <Input placeholder="아이디" />
        </Form.Item>
        <Form.Item
          layout="vertical"
          name="zipcode"
          label="우편번호"
          rules={[{ required: true, message: '우편번호를 입력해주세요' }]}
          extra={
            <Button
              color="primary"
              fill="outline"
              onClick={() => setModalState(true)}
            >
              주소찾기
            </Button>
          }
        >
          <Input placeholder="우편번호" disabled />
        </Form.Item>
        <Form.Item
          name="address"
          label="주소"
          rules={[{ required: true, message: '주소' }]}
        >
          <Input placeholder="주소" disabled />
        </Form.Item>
        <Form.Item
          name="addressDetail"
          label="상세주소"
          rules={[{ required: true, message: '상세주소를 입력해주세요' }]}
        >
          <Input placeholder="상세주소" />
        </Form.Item>
        <Form.Item
          name="password"
          label="비밀번호"
          rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
        >
          <Input placeholder="비밀번호" type="password" />
        </Form.Item>
        <Form.Item
          name="passwordCheck"
          label="비밀번호 확인"
          rules={[
            { required: true, message: '비밀번호가 일치 하지 않습니다.' },
          ]}
        >
          <Input placeholder="비밀번호 확인" type="password" />
        </Form.Item>
        <Form.Item
          name="mobile"
          label="휴대폰 번호"
          rules={[{ required: true, message: '휴대폰 번호를 입력해주세요' }]}
        >
          <Input placeholder="휴대폰 번호" type="tel" />
        </Form.Item>
      </Form>
      <Popup
        position="bottom"
        visible={modalState}
        showCloseButton
        onClose={() => {
          setModalState(false);
        }}
        bodyStyle={{ height: '100%', paddingTop: '40px' }}
      >
        <DaumPostcode
          onComplete={onCompletePost}
          style={postCodeStyle}
          autoClose={false}
        />
      </Popup>
    </>
  );
};

export default Info;
