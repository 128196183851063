import styled from 'styled-components';

const Title = styled.div`
  font-size: 14px;
  color: #000;
  margin-top: 4px;
`;

const Date = styled.div`
  font-size: 12px;
  color: #9c9c9c;
  margin: 6px 0px;
`;

export { Date, Title };
