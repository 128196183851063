import { api, EndPoints } from 'services/api-config';
import { queryClient } from 'services/queryClient';
import { useMutation } from 'react-query';
import { CartDeleteBody } from '../cart';

export const useDeleteCart = () =>
  useMutation(
    async (form: CartDeleteBody) => {
      const { data } = await api.delete(`${EndPoints.cart}/${form.cart_idx}`);
      return data;
    },
    {
      onSuccess: (data, { cart_idx }) => {
        const prevContent = queryClient.getQueryData<any>('cart');

        const newContnets = prevContent.filter(
          (item: any) => item.idx !== cart_idx,
        );

        queryClient.setQueriesData('cart', [...newContnets]);
      },
    },
  );
