import { Collapse } from 'antd-mobile';
import moment from 'moment';
import { InfiniteData } from 'react-query';
import { NoticeModel } from 'services/queries/notice/notice';
import * as S from './styled';

interface NoticeListProops {
  notice: InfiniteData<NoticeModel[]> | undefined;
}

const NoticeList = ({ notice }: NoticeListProops) => (
  <Collapse accordion>
    {notice !== undefined &&
      notice.pages.map((pageData) =>
        pageData.map((item) => (
          <Collapse.Panel
            key={String(item.idx)}
            title={
              <>
                {item.title}
                <S.Date>{moment(item.createdAt).format('YYYY-MM-DD')}</S.Date>
              </>
            }
          >
            {item.contents}
          </Collapse.Panel>
        )),
      )}
  </Collapse>
);

export default NoticeList;
