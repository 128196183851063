import { Button, Form, Input, TextArea } from 'antd-mobile';
import React from 'react';

interface QnaWriteProps {
  onFinish: (value: any) => void;
}

const QnaWrite = ({ onFinish }: QnaWriteProps) => (
  <Form
    onFinish={onFinish}
    footer={
      <Button block type="submit" color="primary" size="large">
        등록하기
      </Button>
    }
  >
    <Form.Item
      name="title"
      label="제목"
      rules={[{ required: true, message: '제목을 입력해주세요' }]}
    >
      <Input placeholder="제목을 입력해주세요" />
    </Form.Item>
    <Form.Item
      name="content"
      label="내용"
      rules={[{ required: true, message: '내용을 입력해주세요' }]}
    >
      <TextArea
        placeholder="내용을 입력해주세요"
        maxLength={300}
        rows={2}
        showCount
      />
    </Form.Item>
  </Form>
);

export default QnaWrite;
