import MypageComponent from 'components/domain/mypage';
import Container from 'components/ui/layouts/container';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { setAxiosHeader } from 'services/api-config';
import {
  useFetchUser,
  useFetchUserBan,
} from 'services/queries/user/hooks/useFetchUser';
import { usePostLogin } from 'services/queries/user/hooks/usePostUser';
import { queryClient } from 'services/queryClient';
import Footer from "../../components/ui/layouts/footer";

const Mypage = () => {
  const { data: userInfo } = useFetchUser();
  const { data: userBan } = useFetchUserBan();
  const [cookies, setCookie, removeCookie] = useCookies([
    'logged_noAuth',
    'logged_auth',
    'firetoken',
  ]);
  const { data: user, mutate: loginAction } = usePostLogin();
  const navigator = useNavigate();

  const logout = () => {
    queryClient.clear();
    removeCookie('logged_auth', { path: '/' });
    loginAction({
      loginId: '',
      password: '',
      firebaseToken: cookies.firetoken,
    });
  };

  useEffect(() => {
    if (user !== undefined) {
      setCookie('logged_noAuth', user, {
        path: '/',
        expires: new Date(Date.now() + user.accessTokenExpire),
      });
    }
  }, [user]);

  useEffect(() => {
    if (user !== undefined) {
      setAxiosHeader('authorization', `Bearer ${user.accessToken}`);
      navigator('/main');
    }
  }, [cookies]);

  const getState = (idx: string) => {
    if (Number(idx) === 0) {
      return 'good';
    }

    if (Number(idx) >= 1 && Number(idx) <= 3) {
      return 'soso';
    }

    return 'bad';
  };

  return (
    <Container type="main">
      {userInfo && userBan && (
        <MypageComponent
          state={getState(userBan.reportStatus)}
          nickname={userInfo.nickName}
          mobile={userInfo.mobile}
          logout={logout}
        />
      )}
      <Footer />
    </Container>
  );
};

export default Mypage;
