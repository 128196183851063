import { Collapse } from 'antd-mobile';
import { InfiniteData } from 'react-query';
import { FaqModel } from 'services/queries/faq/faq';

interface FaqListProops {
  list: InfiniteData<FaqModel[]> | undefined;
}

const FaqList = ({ list }: FaqListProops) => (
  <Collapse accordion>
    {list !== undefined &&
      list.pages.map((pageData) =>
        pageData.map((item) => (
          <Collapse.Panel key={String(item.idx)} title={item.title}>
            {item.content}
          </Collapse.Panel>
        )),
      )}
  </Collapse>
);

export default FaqList;
