import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 48px;
  padding: 36px 0px;
  text-align: center;
  h3 {
    font-size: 15px;
    margin-top: 15px;
  }
`;

const ErrorBlock = styled.div``;

export { Wrapper, ErrorBlock };
