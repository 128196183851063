import Container from 'components/ui/layouts/container';
// import { useNavigate } from 'react-router-dom';
import QnaWrite from 'components/domain/mypage/qna/write';
import { usePostQna } from 'services/queries/qna/hooks/usePostQna';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const QnaWritePage = () => {
  const navigator = useNavigate();

  const { data: qna, mutate: writeQna } = usePostQna();
  const onFinish = (value: any) => {
    writeQna({
      title: value.title,
      content: value.content,
    });
  };

  useEffect(() => {
    if (qna !== undefined) {
      navigator('/mypage/qna');
    }
  }, [qna]);

  return (
    <Container type="sub" title="1:1 문의 작성하기">
      <QnaWrite onFinish={onFinish} />
    </Container>
  );
};

export default QnaWritePage;
