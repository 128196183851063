import MainList from 'components/domain/main/list';
import Filter from 'components/domain/main/filter';
import Container from 'components/ui/layouts/container';
import { useFetchContnet } from 'services/queries/content/hooks/useFetchContent';
import InfiniteScroll from 'react-infinite-scroller';
import { usePostLike } from 'services/queries/like/hooks/usePostLike';
import { useFetchLayoutCate } from 'services/queries/layout/hooks/useFetchLayout';
import { useEffect, useState } from 'react';
import useAccess from 'hooks/useAccess';
import { LikeBody } from 'services/queries/like/like';
import { Toast } from 'antd-mobile';
import { useCookies } from 'react-cookie';
import Footer from "../../components/ui/layouts/footer";

function MainPage() {
  const [cookies, setCookie] = useCookies();
  const [category, setCategory] = useState<string[]>(
    cookies.main_category ? cookies.main_category : [],
  );
  const {
    data: contents,
    fetchNextPage,
    refetch: refetchContents,
    hasNextPage,
  } = useFetchContnet({
    page: 1,
    pageSize: 10,
    category: category.length !== 0 ? category.toString() : '0',
  });
  const { data: cate } = useFetchLayoutCate();
  const { mutate: actionLike } = usePostLike('main');
  const { accessCheck } = useAccess({ isAuth: true });

  useEffect(() => {
    refetchContents();
  }, [category]);

  const onLike = (form: LikeBody, isLike: boolean) => {
    Toast.show({
      content: isLike
        ? '좋아요가 취소 되었습니다.'
        : '좋아요가 추가 되었습니다.',
      position: 'bottom',
    });

    actionLike(form);
  };

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  const onFinish = (value: string[]) => {
    setCookie('main_category', value, {
      path: '/',
    });
    setCategory(value);
  };

  return (
    <Container type="main">
      {cate && (
        <Filter value={category} categoryList={cate} onFinish={onFinish} />
      )}
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <MainList data={contents} onLike={onLike} />
      </InfiniteScroll>
      <Footer />
    </Container>
  );
}

export default MainPage;
