import { Modal } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/action-sheet';
import { useConfirm } from 'store/confirmStore';
import parse from 'html-react-parser';

const ConfirmModal = () => {
  const {
    open,
    title,
    msg,
    cancelBtnNm,
    confirmBtnMn,
    confirmAction,
    cancelAction,
    closeConfirm,
  } = useConfirm();

  return (
    <Modal
      visible={open}
      title={title}
      content={<div style={{ textAlign: 'center' }}>{parse(msg)}</div>}
      closeOnAction
      onAction={(action: Action) => {
        if (action.key === 'confirm') {
          if (confirmAction !== undefined) confirmAction();
        }
        if (action.key === 'cancel') {
          if (cancelAction !== undefined) cancelAction();
        }

        closeConfirm();
      }}
      actions={[
        {
          key: 'confirm',
          text: confirmBtnMn,
          primary: true,
        },
        {
          key: 'cancel',
          text: cancelBtnNm,
        },
      ]}
    />
  );
};

export default ConfirmModal;
