import { api, EndPoints } from 'services/api-config';
import { useMutation } from 'react-query';
import { OrderBody, OrderCompleteBody, OrderSendBody } from '../order';

export const usePostOrder = () =>
  useMutation(async (form: OrderBody[]) => {
    const { data } = await api.post(`${EndPoints.order}`, form);
    return data;
  });

export const usePostOrderSend = () =>
  useMutation(async (form: OrderSendBody) => {
    const { data } = await api.post(`${EndPoints.order}/${form.order_idx}`, {
      courierIdx: form.courierIdx,
      transportNumber: form.transportNumber,
    });
    return data;
  });

export const usePostOrderCom = () =>
  useMutation(async (form: OrderCompleteBody) => {
    const { data } = await api.post(`${EndPoints.order}/${form.order_idx}`);
    return data;
  });
