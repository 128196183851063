import { useInfiniteQuery } from 'react-query';
import { api, EndPoints } from 'services/api-config';
import { SearchParams, SearchCardModel, SearchCotentModel } from '../search';

export const useFetchSearchContent = ({
  page,
  pageSize,
  keyword,
  category,
}: SearchParams) =>
  useInfiniteQuery(
    ['infiniteSearchContent'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<SearchCotentModel[]>(
        `${EndPoints.search}/content`,
        {
          params: {
            page: pageParam,
            pageSize,
            keyword,
            category,
          },
        },
      );
      return data;
    },
    {
      enabled: keyword === '',
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchSearchCard = ({
  page,
  pageSize,
  keyword,
  category,
}: SearchParams) =>
  useInfiniteQuery(
    ['infiniteSearchCard'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<SearchCardModel[]>(
        `${EndPoints.search}/card`,
        {
          params: {
            page: pageParam,
            pageSize,
            keyword,
            category,
          },
        },
      );
      return data;
    },
    {
      enabled: category === undefined && keyword === undefined,
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );
