import InfiniteScroll from 'react-infinite-scroller';
import NoticeList from 'components/domain/mypage/notice';
import Container from 'components/ui/layouts/container';
import { useFetchNotice } from 'services/queries/notice/hooks/useFetchNotice';

const NoticePage = () => {
  const {
    data: notice,
    fetchNextPage,
    hasNextPage,
  } = useFetchNotice({ page: 1, pageSize: 10 });

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  return (
    <Container type="sub" title="공지사항">
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <NoticeList notice={notice} />
      </InfiniteScroll>
    </Container>
  );
};

export default NoticePage;
