import styled from 'styled-components';

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  line-height: 1.5;
  padding: 12px;
  color: #0040dc;
`;

const WriteImgWrapper = styled.div`
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export { AddButton, WriteImgWrapper };
