import { useInfiniteQuery, useQuery } from 'react-query';
import { api, EndPoints } from 'services/api-config';
import {
  EventDetailModel,
  EventDetailParams,
  EventModel,
  EventParams,
} from '../event';

export const useFetchEvent = ({ page, pageSize }: EventParams) =>
  useInfiniteQuery(
    ['event'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<EventModel[]>(`${EndPoints.event}`, {
        params: {
          page: pageParam,
          pageSize,
        },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchEventDetail = ({ event_idx }: EventDetailParams) =>
  useQuery(['eventDetail', event_idx], async () => {
    const { data } = await api.get<EventDetailModel>(
      `${EndPoints.event}/${event_idx}`,
    );
    return data;
  });
