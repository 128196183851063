import LikeList from 'components/domain/like/list';
import Container from 'components/ui/layouts/container';
import { useFetchLikeContent } from 'services/queries/content/hooks/useFetchContent';
import InfiniteScroll from 'react-infinite-scroller';
import { usePostLike } from 'services/queries/like/hooks/usePostLike';
import { LikeBody } from 'services/queries/like/like';
import { Toast } from 'antd-mobile';

const LikePage = () => {
  const {
    data: contents,
    fetchNextPage,
    hasNextPage,
  } = useFetchLikeContent({
    page: 1,
    pageSize: 10,
  });

  const { mutate: actionLike } = usePostLike('like');

  const onLike = (form: LikeBody) => {
    Toast.show({
      content: '좋아요가 취소 되었습니다.',
      position: 'bottom',
    });
    actionLike(form);
  };

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  return (
    <Container title="찜하기" type="sub">
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <LikeList data={contents} onLike={onLike} />
      </InfiniteScroll>
    </Container>
  );
};

export default LikePage;
