import styled from 'styled-components';

const PointList = styled.div`
  border-bottom: 1px solid #eee;
  padding: 15px 20px;
  background: #fff;
`;

const PointListDate = styled.div`
  color: #a9a9a9;
  font-size: 13px;
`;

const PointListText = styled.div`
  margin-top: 8px;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  font-weight: bold;
  span {
    color: #1e90ff;
  }
`;

const Wrapper = styled.div``;

export { PointList, PointListDate, PointListText, Wrapper };
