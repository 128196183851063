import { UserOrderDetailResponse } from 'services/queries/user/user';
import * as S from './styled';

interface OrderDetailListProps {
  data: UserOrderDetailResponse;
}

const OrderDetailList = ({ data }: OrderDetailListProps) => (
  <S.ProductListWrap>
    {data.products.map((v) => (
      <S.ProductList>
        <img src={v.imageUrl} alt="" />
        <h2>{v.name}</h2>
        <h3>{v.point.toLocaleString()}원</h3>
        <span>수량 : {v.quantity}</span>
      </S.ProductList>
    ))}
  </S.ProductListWrap>
);

export default OrderDetailList;
