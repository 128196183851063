import WriteCard from 'components/domain/wrtie/card';
import FilterSearchModal from 'components/ui/common/modal/filterSearchModal';
import Container from 'components/ui/layouts/container';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  usePostContent,
  usePostContentProduct,
} from 'services/queries/content/hooks/usePostContnet';
import { useFetchLayoutCate } from 'services/queries/layout/hooks/useFetchLayout';
import { useFetchSearchCard } from 'services/queries/search/hooks/useFetchSearch';
import { useAlert } from 'store/alertStore';

const WrtieCardPage = () => {
  const { data: cate } = useFetchLayoutCate();
  const { data: content, mutate: addContent } = usePostContent();
  const { data: product, mutate: addProduct } = usePostContentProduct();
  const [searchVisible, setSearchVisible] = useState(false);
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [keyword, setKeyWord] = useState<string | undefined>(undefined);
  const {
    data: searchList,
    fetchNextPage,
    refetch: refetchSearch,
    remove: removeSearch,
    hasNextPage,
  } = useFetchSearchCard({
    page: 1,
    pageSize: 20,
    category,
    keyword,
  });

  const { showAlert } = useAlert();
  const [contents, setContents] = useState<any>([]);
  const [searchContent, setSearchContent] = useState<any>([]);
  const navigator = useNavigate();

  useEffect(() => {
    if (content) {
      if (contents.length > 0) {
        contents.forEach((val: any) => {
          const form = val;
          if (val.image !== undefined) form.image = val.image[0].file;
          if (val.idx !== undefined) form.card_idx = val.idx;

          delete form.idx;
          delete form.imageUrl;

          addProduct({
            content_idx: content.idx,
            form: val,
          });
        });
      }
    }
  }, [content]);

  useEffect(() => {
    if (product) {
      showAlert(
        {
          title: '안내',
          msg: '컨텐츠 등록이 완료 되었습니다.',
          btnNm: '확인',
        },
        () => {
          navigator('/main');
        },
      );
    }
  }, [product]);

  useEffect(() => {
    if (category !== undefined && keyword !== undefined) {
      refetchSearch();
    } else {
      removeSearch();
    }
  }, [category, keyword]);

  const onFinish = (value: any) => {
    const form = value;

    if (form.contents.length === 0) {
      showAlert({
        title: '안내',
        msg: '카드를 등록해주세요.',
        btnNm: '확인',
      });
    }

    form.image = value.images[0].file;
    setContents(form.contents);

    delete form.images;
    delete form.contents;

    addContent(form);
  };

  const InfinitiLoad = () => {
    if (category !== undefined && keyword !== '') {
      fetchNextPage({
        cancelRefetch: false,
      });
    }
  };

  return (
    <>
      <Container title="카드 작성" type="sub">
        {cate && (
          <WriteCard
            onFinish={onFinish}
            categoryList={cate}
            contents={searchContent}
            setContents={setSearchContent}
            openSearch={setSearchVisible}
            setCategory={setCategory}
          />
        )}
      </Container>
      {searchVisible && (
        <FilterSearchModal
          visible={searchVisible}
          setVisible={setSearchVisible}
          loadMore={InfinitiLoad}
          hasMore={hasNextPage}
          onSearch={setKeyWord}
          setContents={setSearchContent}
          filteredItems={searchList}
        />
      )}
    </>
  );
};

export default WrtieCardPage;
