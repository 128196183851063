import { useInfiniteQuery, useQuery } from 'react-query';
import { api, EndPoints } from 'services/api-config';
import { FaqMenuModal, FaqModel, FaqParams } from '../faq';

export const useFetchFaq = ({ page, pageSize, classification }: FaqParams) =>
  useInfiniteQuery(
    ['faq', classification],
    async ({ pageParam = page }) => {
      const { data } = await api.get<FaqModel[]>(`${EndPoints.faq}`, {
        params: {
          page: pageParam,
          pageSize,
          classification,
        },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchFaqMenu = () =>
  useQuery(['useFetchFaqMenu'], async () => {
    const { data } = await api.get<FaqMenuModal[]>(
      `${EndPoints.faq}/classification`,
    );
    return data;
  });
