import InfiniteScroll from 'react-infinite-scroller';
import EventList from 'components/domain/mypage/event';
import Container from 'components/ui/layouts/container';
import { useNavigate } from 'react-router-dom';
import { useFetchEvent } from 'services/queries/event/hooks/useFetchEvent';

const EventPage = () => {
  const navigator = useNavigate();
  const {
    data: event,
    fetchNextPage,
    hasNextPage,
  } = useFetchEvent({ page: 1, pageSize: 10 });

  const onClickList = (idx: number) => {
    navigator(`/mypage/event${idx}`);
  };

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  return (
    <Container type="sub" title="이벤트">
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <EventList list={event} onClick={onClickList} />
      </InfiniteScroll>
    </Container>
  );
};

export default EventPage;
