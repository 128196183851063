import { Form, Stepper } from 'antd-mobile';
import {
  ClockCircleOutline,
  DownCircleOutline,
  HeartFill,
  HeartOutline,
} from 'antd-mobile-icons';
import { useState } from 'react';
import { CartAddBody } from 'services/queries/cart/cart';
import { ContentDetailModel } from 'services/queries/content/content';
import { LikeBody } from 'services/queries/like/like';
import { getBeforeDate } from 'utils/date';
import { getState } from 'utils/state';
import * as S from './styled';

interface ContentProps {
  idx: string;
  data: ContentDetailModel;
  onLike: (form: LikeBody, isLike: boolean) => void;
  onCart: (form: CartAddBody[]) => void;
  onFinish: (value: any) => void;
}

const Contnet = ({ idx, data, onFinish, onCart, onLike }: ContentProps) => {
  const state = getState(data.reportStatus);

  const [form] = Form.useForm();

  const [total, setTotal] = useState({
    count: 0,
    price: 0,
  });

  return (
    <>
      <S.MainImg>
        <img src={data.imageUrl} alt="mainImg" />
      </S.MainImg>
      <S.MainInfo>
        <h1>{data.title}</h1>
        <S.MainInfoSub>
          <S.NickName type="good">
            {data.nickName}
            <span>
              {state === 'good' && (
                <>
                  <DownCircleOutline /> 양호
                </>
              )}
              {state === 'soso' && (
                <>
                  <DownCircleOutline /> 주의
                </>
              )}
              {state === 'bad' && (
                <>
                  <DownCircleOutline /> 제한
                </>
              )}
            </span>
          </S.NickName>
          <S.SubInfo>
            <span>
              <ClockCircleOutline /> {getBeforeDate(data.createdAt)}
            </span>
            <span>
              <HeartOutline /> {data.likeCount}
            </span>
          </S.SubInfo>
        </S.MainInfoSub>
      </S.MainInfo>
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={(changedValues: any, allValues: any) => {
          const { count, price } = allValues.products.reduce(
            (prev: any, next: any) => {
              if (next.count !== undefined) {
                return {
                  count: prev.count + next.count,
                  price: prev.price + next.point * next.count,
                };
              }
              return {
                count: prev.count,
                price: prev.price,
              };
            },
            { count: 0, price: 0 },
          );

          setTotal({
            count,
            price,
          });
        }}
        initialValues={{
          products: data.products,
        }}
        footer={
          <S.BtnWrap>
            <S.HeartBtn
              active={data.isLike}
              onClick={() => onLike({ contentIdx: Number(idx) }, data.isLike)}
              type="button"
            >
              {data.isLike ? <HeartFill /> : <HeartOutline />}
            </S.HeartBtn>
            <S.CartBtn
              onClick={() => {
                const formData = form.getFieldValue('products');
                onCart(
                  formData
                    .filter((v: any) => v.count !== undefined)
                    .map((v: any) => ({ idx: v.idx, quantity: v.count })),
                );
              }}
              type="button"
            >
              장바구니
            </S.CartBtn>
            <S.BuyBtn type="submit">구매하기</S.BuyBtn>
          </S.BtnWrap>
        }
      >
        <Form.Array name="products">
          {(fields) =>
            fields.map(({ index }) => (
              <S.ProductListWrap>
                <S.ProductList>
                  <img src={data.products[index].imageUrl} alt="" />
                  <h2>{data.products[index].name}</h2>
                  <h3>{data.products[index].point.toLocaleString()}원</h3>
                  <span>수량 : {data.products[index].remains}개</span>
                  <Form.Item name={[index, 'count']}>
                    <Stepper max={data.products[index].remains} min={0} />
                  </Form.Item>
                </S.ProductList>
              </S.ProductListWrap>
            ))
          }
        </Form.Array>
      </Form>
      <S.TotalWrap>
        <span>
          <strong>총 결제 예정 금액</strong> ( 총 : {total.count}건 )
        </span>
        <span>{total.price.toLocaleString()}원</span>
      </S.TotalWrap>
    </>
  );
};

export default Contnet;
