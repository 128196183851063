import styled from 'styled-components';

const PointWrap = styled.div`
  margin: 20px;
  font-size: 0.9rem;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding: 20px;
  > div + div {
    margin-top: 20px;
  }
  .adm-list-item {
    padding-left: 0;
  }
  .adm-list-item-content {
    border: 0;
  }
  .adm-list-item-content-main {
    padding: 0;
  }
  .adm-space-item input {
    border: 1px solid #e2e2e2;
    width: 130px;
  }
`;

export { PointWrap };
