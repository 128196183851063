import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #999999;
    font-size: 12px;
    font-weight: normal;
  }
  border-bottom: 1px solid #eee;
  padding: 15px;
  font-size: 16px;
  background: #fff;
  font-weight: bold;
`;

const Contnet = styled.div`
  font-size: 14px;
  color: #9c9c9c;
  padding: 15px;
  border-bottom: 1px solid #eee;
  background: #fff;
  min-height: 200px;
  color: #000;
  span {
    margin-bottom: 10px;
  }
`;

export { Title, Contnet };
