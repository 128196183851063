import React from "react";
import Container from "components/ui/layouts/container";




function TermPage() {
    return (
        <Container type="sub" title="이용약관">
            <p className="term header">판다워치 서비스 이용약관</p>
                <div className="term content">
                        <h1>제1조 (목적)</h1>
                        <p>본 판다워치 서비스 이용약관(이하 “약관이라 함”)은, 상호 판다워치(이하 “사업자”라 함)가 제공하는 중고물품거래 중개서비스(이하 “서비스”라 함)를 이용하는 사람(이하 “판매자”라 함)간의 권리와 의무 및 제반사항을 규정합니다.</p>
                        <h1 className="term title">제2조 (용어의 정의)</h1>
                        <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                        <ol>
                                <li>1. “이용자”란 약관에 동의하여 판다워치 서비스에 가입한 뒤 고유번호를 부여받아 사업자의 서비스를 이용하는 사람을 의미합니다.</li>
                                <li>2. “판매자”란 판다워치의 이용자로서 고유 프로필을 생성하고 상품판매 서비스를 이용하는 데 있어 이 약관을 준수하기로 동의한 사람을 의미합니다.</li>
                                <li>3. “고유 프로필”이란 판다워치 서비스 내에서 판매자의 물품 거래 정보를 다른 이용자에게 제공하고 재화 또는 용역에 관한 정보를 노출할 수 있는 서비스를 의미합니다.</li>
                                <li>4. “서비스”란 판매자가 등록한 재화 또는 용역을 다른 이용자에게 판매할 수 있도록 사업자가 중개하는 일체의 활동을 의미합니다.</li>
                                <li>5. “포인트”란 판다워치 서비스 내에서 물품 거래 용도로 사용할 수 있고, 현금으로 출금할 수 있으며, 판다워치 내에서만 사용할 수 있는 가상의 화폐입니다.</li>
                        </ol>
                        <h1 className="term title">제3조 (약관의 효력)</h1>
                        <ol>
                                <li>① 사업자는 본 약관을 판다워치 서비스 화면에 게시하여, 서비스를 이용하고자 하는 판매자가 본 약관을 확인할 수 있도록 합니다.</li>
                                <li>② 본 약관에 의해 서비스를 이용하고자 하는 자는 이 약관의 내용을 숙지하고 판매자와 사업자 간의 권리, 의무관계에 대해 동의함을 확인합니다.</li>
                                <li>③ 사업자는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관을 개정할 수 있으며, 이 경우 개정 내용과 적용 일자를 명시하여 판다워치 서비스를 통해 공지합니다.</li>
                                <li>④ 판매자가 개정약관에 동의하지 않는 경우 개정약관의 적용일 이전에 거부 의사를 표시할 수 있으며, 이 경우 판매자가 사업자의 서비스를 이용하지 않겠다는 의사표시를 한 것으로 간주합니다.</li>
                                <li>⑤ 사업자가 개정약관을 공지하였으나 판매자가 명시적으로 거부 의사를 표명하지 아니한 경우 개정약관에 동의한 것으로 봅니다.</li>
                        </ol>
                        <h1 className="term title">제4조 (약관의 성립)</h1>
                        <ol>
                                <li>① 이 약관은 서비스를 이용하고자 하는 자가 이 약관에 동의하고 사업자가 정한 절차에 따라 서비스 이용 신청을 하며, 이에 대해 사업자가 심사를 거쳐 승낙함으로써 성립합니다. 사업자는 이용승낙의 의사표시를 해당 서비스 화면에 게시하거나 전자우편 또는 기타 방법으로 할 수 있습니다.</li>
                                <li>② 이용신청자는 등록신청의 정확성을 확인하기 위하여 사업자가 별도로 요청하는 증빙서류가 있을 경우 신속히 제출하여야 하며, 사업자는 해당 서류를 징구하는 시점까지 가입 신청에 대한 승낙을 보류하거나 거부할 수 있습니다.</li>
                                <li>③ 사업자는 제1항에 따라 판매 서비스 이용을 신청한 자 중에 아래 각호에 해당하는 경우에는 승인을 거부할 수 있으며, 등록된 이후에도 아래 각호의 사유가확인된 경우 승낙을 취소할 수 있습니다.
                                        <ol>
                                                <li>1. 실명이 아니거나 타인의 정보(전화번호, 사업자번호 등)를 이용한 경우</li>
                                                <li>2. 허위의 정보를 기재하거나, 사업자가 요구하는 내용을 기재하지 않은 경우</li>
                                                <li>3. 사업자로부터 판매 중단 조치 등을 받은 판매자가 그 조치 기간에 이 약관을 임의 해지하고 재이용신청을 하는 경우</li>
                                                <li>4. 가입을 신청한 자의 사유로 인하여 승인이 불가능하거나 필수서류 또는 사업자가 요청한 서류를 제출하지 않는 등 제반 사항을 위반하여 신청하는 경우</li>
                                                <li>5. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청 등 사업자가 합리적인 판단에 따라 필요하다고 인정하는 경우</li>
                                        </ol>
                                </li>
                                <li>④ 판매자는 서비스 이용신청 시 제공한 정보가 변경되는 경우 즉시 사업자에게 변경된 정보를 제공하여야 하며, 사업자는 판매자가 정당한 사유 없이 변경된 정보를 제공하지 않는 경우 약관의 해지, 서비스 이용 제한 또는 결제 금액의 지급 또는 정산 보류를 포함하여 필요한 조치를 취할 수 있습니다. 이 경우 판매자는 사업자의 조치에 따라 제3자에게 발생할 수 있는 사항에 대한 모든 책임을 부담합니다.</li>
                        </ol>
                        <h1 className="term title">제5조 (각 당사자의 역할과 의무)</h1>
                        <ol>
                                <li>① 사업자와 판매자는 서로 독립적인 경제주체이며, 이 약관에 대한 동의 또는 승낙에 따라 사업자와 판매자 사이에 고용, 도급, 대리, 가맹(프랜차이즈), 납품, 위탁판매, 모자사업자, 합작, 동업, 조합 등 어떠한 다른 관계도 성립하지 않음을 상호 명확히 확인합니다. 판매자는 어떤 경우에도 자신이 판다워치와 위와 같은 관계에 있음을 표시, 암시하거나 이용자가 위와 같은 관계가 있는 것 같이 오인 할 수 있는 행위를 하여서는 아니 됩니다.</li>
                                <li>② 사업자는 이 서비스를 위하여, 판매자가 재화 또는 서비스를 등록하여 판매할 수 있는 기능을 제공하고 시스템을 운영, 관리하며, 사업자가 통신판매의 당사자가 아니라는 사실을 이용자에게 고지합니다.</li>
                                <li>③ 판매자는 이 서비스를 통해 스스로 정한 판매 조건(상품의 종류, 서비스 조건, 배송 또는 설치 조건, 보증 또는 할인 등)에 따라 재화 또는 서비스를 자신의 상호나 이름 등 고유의 명칭으로 판매하며, 이용자가 구매를 신청한 재화 또는 서비스에 대하여 판매가 확정된 경우 이를 이용자에게 배송, 전달하거나 서비스를 제공하여야 합니다. 판매자는 이 서비스를 통해 자신이 등록한 상품 정보의 이미지, 영상, 텍스트 등 모든 내용, 자신과 이용자 사이에 이루어지는 모든 거래행위 자체와 등록한 상품에 대하여 품질의 적합성, 완전성, 안전성, 합법성 또는 제3자 권리를 침해하지 않는다는 점 등 거래에 관한 모든 책임을 스스로 부담하며, 거래에 관하여 이용자와 분쟁이 발생하는 경우 자신의 비용과 책임으로 이를 모두 해결하여야 합니다.</li>
                        </ol>
                        <h1>제6조 (수수료의 책정 및 정산)</h1>
                        <ol>
                                <li>① 판다워치는 판매자의 서비스 이용에 대해 서비스이용료 및 결제대행수수료를 부과할 수 있으며, 서비스이용료의 구체적인 사항 및 요율은 별도의 서비스 안내 페이지를 통해 공지합니다.</li>
                                <li>② 판매자 또는 이용자는 서비스를 이용하기 위하여 사업자가 제공하는 포인트를 카드결제, 계좌이체 등의 방법으로 구매할 수 있습니다. 이 경우 사업자는 부가세 및 결제대행수수료를 공제하거나 추가 과금하는 등의 방식으로 정산한 포인트를 판매자 또는 이용자에게 사업자의 정책에 따라 지급합니다.</li>
                                <li>③ 서비스를 통해 상품이 구매확정되는 경우, 사업자는 판매자를 대신하여 모든 판매대금(구매자가 상품의 구매를 위하여 자신의 금전 또는 포인트를 이용하여 결제한 금액)을 수령하며, 사업자의 정산 일정 및 정책에 따라 유상결제액 기준 판매대금에서 서비스이용료 및 결제대행수수료를 공제한 나머지 금액을 판매자에게 포인트로 지급합니다.</li>
                                <li>④ 판매자는 자신이 보유한 포인트를 사업자가 정한 방법에 따라서 출금해 줄것을 신청할 수 있습니다. 사업자는 이러한 출금 신청을 받은 경우, 사업자의 정산 일정 및 정책에 따라 해당 포인트를 판매자에게 계좌이체 등의 방법으로 정산하여 판매자에게 지급합니다.</li>
                        </ol>
                        <h1>제7조 (계약의 해지 및 손해배상)</h1>
                        <ol>
                                <li>① 당사자 일방은 다음 각호에 해당하는 귀책사유가 있는 상대방에게 10영업일의 기간을 정하여 시정을 최고한 후 그 기간 내 시정되지 않으면 이 약관을 해지할 수 있습니다.
                                        <ol>
                                                <li>1. 상대방이 이 약관상의 의무를 불이행하는 경우</li>
                                                <li>2. 상대방이 일방 당사자의 명예 또는 신용을 훼손한 경우</li>
                                                <li>3. 기타 이 약관의 목적을 달성하는 것이 객관적으로 불가능한 경우</li>
                                        </ol>
                                </li>
                                <li>② 당사자는 다음의 경우 상대방에 대한 통보로써 즉시 이 약관을 해지할 수 있습니다.
                                        <ol>
                                                <li>1. 상대방이 이 약관에 따른 권리의무 및 데이터를 일방 당사자의 사전 동의 없이 제3자에게 양도, 전매하거나 질권 기타 담보의 목적물로 제공하는 경우</li>
                                                <li>2. 상대방이 중요한 재산에 대하여 강제집행(가압류, 가처분 포함)을 받거나 경매신청을 받았을 경우</li>
                                                <li>3. 상대방에게 부도, 파산, 워크아웃, 회생 또는 파산절차개시 신청 등 사유가 발생한 경우</li>
                                        </ol>
                                </li>
                                <li>③ 본 조에 의해 이 약관이 해지되는 경우 귀책사유가 있는 당사자는 상대방이 입은 손해를 모두 배상해야 합니다. 단, 천재지변 또는 클라우드 서비스의 장애 등 불가항력에 의한 경우는 예외로 합니다.</li>
                        </ol>
                        <h1>제8조 (비밀유지의무)</h1>
                        <ol>
                                <li>① 이 약관과 관련하여 상대방으로부터 제공받은 모든 정보는 다음 각호 어느 하나에 해당하는 경우를 제외하고는 비밀로 하고, 이 약관상의 권리 또는 의무를 이행하기 위한 목적 이외에 이를 사용하거나 제3자에게 제공할 수 없습니다.
                                        <ol>
                                                <li>1. 당사자들이 공개하기로 합의한 사항</li>
                                                <li>2. 공지된 정보</li>
                                                <li>3. 정보를 제공받은 당사자가 제3자로부터 이미 적법하게 취득한 정보</li>
                                                <li>4. 법률에 특별한 규정이 있거나 법령을 준수하기 위하여 불가피하게 정보를 제공하여야 하는 경우</li>
                                        </ol>
                                </li>
                                <li>② 본 조의 비밀유지의무는 이 약관이 이행되지 않거나 해지 기타 사유로 종료된 경우에도 그때로부터 2년간 유효합니다.</li>
                        </ol>
                        <h1>제9조 (취득한 개인정보의 보호)</h1>
                        <ol>
                                <li>① 판매자는 서비스의 이용에 따라 취득한 이용자 등 타인의 개인정보를 상품의 배송 또는 전달을 위한 목적 이외의 용도로 사용하거나 제3자에게 제공하는 등 외부에 유출할 수 없으며, 관련 법령 등에 따라 철저히 보호하여야 합니다.</li>
                                <li>② 판다워치는 배송 등의 목적으로 판매자에게 공개된 이용자의 개인정보를 판다워치의 정책에 따라 상당 기간이 경과한 후 비공개 조치할 수 있습니다.</li>
                                <li>③ 판매자가 본 조를 위반하여 이용자와 분쟁이 발생하는 경우 자신의 노력과 비용으로 판다워치를 면책시켜야 하며, 민/형사상 일체의 법적 책임을 부담해야 합니다.</li>
                        </ol>
                        <h1>제10조 (양도금지)</h1>
                        <p>당사자는 이 약관상의 권리의무를 제3자에게 양도 및 이전하거나 담보로 제공할 수 없습니다.</p>
                        <h1>제11조 (면책)</h1>
                        <ol>
                                <li>① 판다워치는 이용자들과 판매자 사이의 거래를 위한 시스템만을 제공할 뿐, 판매자가 등록한 상품 등에 관한 정보 또는 거래에 관하여 분쟁이 발생한 경우, 그 분쟁의 결과로 인한 책임은 판매자가 부담합니다. 제3자가 판다워치를 상대로 민/형사상 등의 문제를 제기하는 경우 판매자는 문제의 해결을 위하여 필요한 정보를 제공하는 등 적극 협조하여야 하며, 이와 관련하여 판다워치에 손해 또는 비용이 발생하는 경우 이를 배상 또는 보상해야 합니다.</li>
                                <li>② 판다워치는 적법한 권리자의 요구가 있거나, 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우 판다워치 서비스 제공을 일시적으로 중단할 수 있으며, 이와 관련하여 판다워치는 고의 또는 중대한 과실이 없는 한 책임을 부담하지 않습니다.</li>
                        </ol>
                        <h1>제12조 (약관 개정)</h1>
                        <p>약관 변경시 사업자는 판다워치 서비스를 통해 공지합니다. 판매자가 변경되는 약관 내용에 대해 이의가 있는 경우 7일 이내에 제기할 수 있으며, 사업자가 약관 변경을 명확하게 알리거나 통지하였음에도 불구하고 7일 이내에 판매자가 거부 의사를 명시적으로 나타내지 않는 경우에는, 판매자는 약관의 개정에 동의하는 것으로 간주합니다.</p>
                        <h1>제13조 (분쟁의 해결)</h1>
                        <ol>
                                <li>① 이 약관으로 인하여 또는 이 약관과 관련하여 분쟁이 발생하는 경우 당사자들은 일차적으로 협의를 통한 원만한 해결을 도모합니다.</li>
                                <li>② 제1항에 의한 협의가 이루어지지 않는 경우 이 약관으로 인하여 또는 이 약관과 관련하여 발생하는 모든 분쟁의 해결은 서울중앙지방법원을 제1심의 합의관할로 하는 소송에 의합니다.</li>
                        </ol>
                </div>
                <div className="term end">
                        <p>적용 일자 2023년 7월 1일</p>
                </div>
        </Container>
    );
};

export default TermPage;