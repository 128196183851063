import { Button, DatePicker, DatePickerRef, Form } from 'antd-mobile';
import moment from 'moment';

import React, { RefObject } from 'react';

interface PointHistoryFilterProps {
  onFinish: (value: any) => void;
}

const PointHistoryFilter = ({ onFinish }: PointHistoryFilterProps) => (
  <Form
    name="form"
    initialValues={{
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate(),
    }}
    onFinish={onFinish}
    footer={
      <Button block type="submit" color="primary" size="large">
        조회
      </Button>
    }
  >
    <Form.Item
      label="시작일"
      name="startDate"
      trigger="onConfirm"
      onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
        datePickerRef.current?.open();
      }}
    >
      <DatePicker max={new Date()} confirmText="확인" cancelText="취소">
        {(value) =>
          value ? moment(value).format('YYYY-MM-DD') : 'Please select'
        }
      </DatePicker>
    </Form.Item>
    <Form.Item
      label="종료일"
      name="endDate"
      trigger="onConfirm"
      onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
        datePickerRef.current?.open();
      }}
    >
      <DatePicker max={new Date()} confirmText="확인" cancelText="취소">
        {(value) =>
          value ? moment(value).format('YYYY-MM-DD') : 'Please select'
        }
      </DatePicker>
    </Form.Item>
  </Form>
);

export default PointHistoryFilter;
