import React from 'react';
import { Button, Form, Input, List } from 'antd-mobile';
import { Link, useNavigate } from 'react-router-dom';
import {
  ExclamationTriangleOutline,
  FileOutline,
  MessageOutline,
  StarOutline,
} from 'antd-mobile-icons';
import kakaoLoginImage from 'static/images/temp/kakao_login_large_wide.png';
import * as S from './styled';

interface LoginProps {
  onLogin: (value: any) => void;
}

const Login = ({ onLogin }: LoginProps) => {
  const navigator = useNavigate();

  // oauth 요청 URL
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`
  const handleLogin = () => {
    window.location.href = kakaoURL
  }

  return (
    <>
       {/* <Form */}
       {/*   layout="vertical" */}
       {/*   onFinish={onLogin} */}
       {/*   style={{ */}
       {/*     borderTop: 0, */}
       {/*   }} */}
       {/*   footer={ */}
       {/*     <Button block type="submit" color="primary" size="large"> */}
       {/*       로그인 */}
       {/*     </Button> */}
       {/*   } */}
       {/* > */}
       {/*   <Form.Item label="아이디" name="loginId"> */}
       {/*     <Input placeholder="아이디" clearable /> */}
       {/*   </Form.Item> */}
       {/*   <Form.Item label="비밀번호" name="password"> */}
       {/*     <Input placeholder="비밀번호" clearable type="password" /> */}
       {/*   </Form.Item> */}
       {/* </Form> */}
       {/* <S.DriverWrapper> */}
       {/*   <Link to="/signup">회원가입</Link> */}
       {/* </S.DriverWrapper> */}
      <Form
          layout="vertical"
          onFinish={onLogin}
          style={{
            borderTop: 0,
          }}
          footer={
              <img src={kakaoLoginImage} alt="kakao" className="login-kakao" onClick={handleLogin}/>
          }
      />

      <List mode="card">
        <List.Item
          prefix={<ExclamationTriangleOutline />}
          onClick={() => {
            navigator('/mypage/notice');
          }}
        >
          공지사항
        </List.Item>
        <List.Item
          prefix={<StarOutline />}
          onClick={() => {
            navigator('/mypage/event');
          }}
        >
          이벤트
        </List.Item>
        <List.Item
          prefix={<FileOutline />}
          onClick={() => {
            navigator('/mypage/faq');
          }}
        >
          자주 묻는 질문
        </List.Item>
        <List.Item
          prefix={<MessageOutline />}
          onClick={() => {
            navigator('/mypage/qna');
          }}
        >
          1:1 문의
        </List.Item>
      </List>
    </>
  );
};

export default Login;
