import { NavBar, Space } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { BellOutline, SearchOutline } from 'antd-mobile-icons';
import Logo from 'static/images/logo/logo.png';
import * as S from '../styled';

const Header = () => {
  const navigator = useNavigate();
  return (
    <S.Wrapper>
      <NavBar
        left={
          <S.LogoWrapper onClick={() => navigator('/main')}>
            <img src={Logo} alt="판다워치 로고" />
          </S.LogoWrapper>
        }
        right={
          <div style={{ fontSize: 22 }}>
            <Space style={{ '--gap': '16px' }}>
              <SearchOutline onClick={() => navigator('/search/?q=')} />
              <BellOutline onClick={() => navigator('/alarm')} />
            </Space>
          </div>
        }
        backArrow={false}
        style={{
          '--height': '56px',
          '--border-bottom': '1px #eee solid',
        }}
      />
    </S.Wrapper>
  );
};

export default Header;
