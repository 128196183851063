import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
  left: 0;
  margin: 0;
  background: #fff;
  font-weight: bold;
`;

const SearchWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
  left: 0;
  margin: 0;
  background: #fff;
  .adm-nav-bar-title {
    padding: 0;
    flex: 1;
  }
  .adm-nav-bar-left {
    flex: 0;
  }
  .adm-nav-bar-right {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  img {
    height: 22px;
    margin-left: -12px;
  }
`;

export { Wrapper, SearchWrapper, LogoWrapper };
