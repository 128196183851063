import { useInfiniteQuery, useQuery } from 'react-query';
import { api, EndPoints } from 'services/api-config';
import {
  ContentDetailModel,
  ContentDetailParams,
  ContentModel,
  ContentParams,
} from '../content';

export const useFetchContnet = ({ page, pageSize, category }: ContentParams) =>
  useInfiniteQuery(
    ['infiniteContent'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<ContentModel[]>(`${EndPoints.content}`, {
        params: {
          page: pageParam,
          pageSize,
          category,
        },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchLikeContent = ({ page, pageSize }: ContentParams) =>
  useInfiniteQuery(
    ['infiniteLikeContent'],
    async ({ pageParam = page }) => {
      const { data } = await api.get<ContentModel[]>(
        `${EndPoints.content}/like`,
        {
          params: {
            page: pageParam,
            pageSize,
          },
        },
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) =>
        lastPage.length !== 0 ? allPage.length + 1 : undefined,
    },
  );

export const useFetchContentDetail = ({ content_idx }: ContentDetailParams) =>
  useQuery(
    ['contnetDetail', content_idx],
    async () => {
      const { data } = await api.get<ContentDetailModel>(
        `${EndPoints.content}/${content_idx}`,
      );
      return data;
    },
    {
      enabled: content_idx !== undefined,
    },
  );
