import { Button } from 'antd-mobile';
import React from 'react';
import { InfiniteData } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UserOrderModel } from 'services/queries/user/user';
import NoList from 'components/ui/common/noList';
import * as S from './styled';

interface OrderListProps {
  data: InfiniteData<UserOrderModel[]> | undefined;
  status: 'sell' | 'order';
}

const OrderList = ({ data, status }: OrderListProps) => {
  const navigator = useNavigate();
  return data !== undefined && data.pages[0].length === 0 ? (
    <NoList
      title={`${status === 'sell' ? '판매' : '구매'} 내역이 없습니다.`}
      status="default"
    />
  ) : (
    <S.ProductListWrap>
      {data !== undefined &&
        data.pages.map((pageData) =>
          pageData.map((item) => (
            <S.ProductList>
              <img src={item.imageUrl} alt="" />
              <h2>{item.title}</h2>
              <Button
                size="small"
                onClick={() => {
                  navigator(`/mypage/${status}/${item.idx}`);
                }}
              >
                상세 내역 보기
              </Button>
            </S.ProductList>
          )),
        )}
    </S.ProductListWrap>
  );
};

export default OrderList;
