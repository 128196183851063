import styled, { css } from 'styled-components';

type WrapperType = 'main' | 'sub' | 'subNoBottom' | 'content';

interface WrapperProps {
  type: WrapperType;
}

const getWrapperPadding = (radius: WrapperType) => {
  let top;
  let bottom;

  switch (radius) {
    case 'main':
      top = '56px';
      bottom = '50px';
      break;

    case 'sub':
      top = '56px';
      bottom = '50px';
      break;

    case 'subNoBottom':
      top = '56px';
      bottom = '50px';
      break;

    case 'content':
      top = '56px';
      bottom = '93px';
      break;

    default:
      return null;
  }

  return css`
    padding-top: ${top};
    padding-bottom: ${bottom};
  `;
};

const Wrapper = styled.div<WrapperProps>`
  ${({ type }) => getWrapperPadding(type)};
  height: 100%;
`;

export { Wrapper };
