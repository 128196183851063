import Card from 'components/ui/common/card';
import { InfiniteData } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { UserCotnetModel } from 'services/queries/user/user';

import { getBeforeDate } from 'utils/date';
import NoList from 'components/ui/common/noList';
import * as S from './styled';

interface MyCotnentListProps {
  data: InfiniteData<UserCotnetModel[]> | undefined;
}

const MyCotnentList = ({ data }: MyCotnentListProps) => {
  const navigator = useNavigate();

  const onClick = (idx: number) => {
    navigator(`/content/${idx}`);
  };

  return data !== undefined && data.pages[0].length === 0 ? (
    <NoList title="작성 글이 없습니다." status="default" />
  ) : (
    <S.Wrapper>
      {data !== undefined &&
        data.pages.map((pageData) =>
          pageData.map((card) => (
            <Card
              idx={card.idx}
              onClick={onClick}
              key={card.idx}
              title={card.title}
              time={getBeforeDate(card.createdAt)}
              like={card.likeCount}
              imgUrl={card.imageUrl}
            />
          )),
        )}
    </S.Wrapper>
  );
};

export default MyCotnentList;
