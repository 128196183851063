import styled from 'styled-components';

const PointTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  th,
  td {
    padding: 12px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    font-size: 15px;
  }

  th {
    border-top: 0;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 50px;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid rgb(244, 244, 250);
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 56px;
`;

const OrderBtn = styled.button`
  width: 100%;
  background-color: #0040dc;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  height: 100%;
`;

export { PointTable, BtnWrap, OrderBtn };
