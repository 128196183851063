import { Button, Checkbox, Form, Space, Stepper } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { CartModel } from 'services/queries/cart/cart';
import NoList from 'components/ui/common/noList';
import * as S from './styled';

interface CartListProps {
  data: CartModel[];
  onFinish: (value: any) => void;
  onDelete: (value: any) => void;
}

const CartList = ({ data, onFinish, onDelete }: CartListProps) => {
  const [total, setTotal] = useState({
    quantity: 0,
    point: 0,
  });

  const [checkLength, setCheckLength] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    setTotal({
      quantity: 0,
      point: 0,
    });
    setCheckLength(0);
  }, [data]);

  return data.length === 0 ? (
    <NoList status="cart" title="장바구니 내역이 없습니다" />
  ) : (
    <>
      <Form
        form={form}
        initialValues={{
          allCheck: false,
          products: data,
        }}
        onFinish={onFinish}
        // 코드 수정 필요
        onValuesChange={(changedValues: any, allValues: any) => {
          const list = form.getFieldValue('products');

          setCheckLength(
            allValues.products.filter(
              (v: any) => v.check && v.check[0] === 'true',
            ).length,
          );

          if (changedValues.allCheck === true) {
            form.setFieldValue(
              'products',
              list.map((v: any) => ({ ...v, check: ['true'] })),
            );

            setCheckLength(allValues.products.length);
          }

          if (changedValues.allCheck === false) {
            form.setFieldValue(
              'products',
              list.map((v: any) => ({ ...v, check: [] })),
            );

            setCheckLength(0);
          }

          const { quantity, point } = allValues.products.reduce(
            (prev: any, next: any) => {
              if (changedValues.allCheck === false) {
                return {
                  quantity: 0,
                  point: 0,
                };
              }

              if (
                (next.check !== undefined && next.check[0] === 'true') ||
                changedValues.allCheck === true
              ) {
                return {
                  quantity: prev.quantity + next.quantity,
                  point: prev.point + next.point * next.quantity,
                };
              }

              return {
                quantity: prev.quantity,
                point: prev.point,
              };
            },
            { quantity: 0, point: 0 },
          );

          setTotal({
            quantity,
            point,
          });
        }}
        footer={
          <S.BtnWrap>
            <S.OrderBtn type="submit">주문하기</S.OrderBtn>
          </S.BtnWrap>
        }
      >
        <Space
          justify="between"
          block
          align="center"
          style={{ paddingRight: 16 }}
        >
          <Form.Item name="allCheck">
            <Checkbox
              style={{
                '--icon-size': '22px',
                '--font-size': '15px',
              }}
              checked={checkLength === data.length && checkLength !== 0}
            >
              전체 선택
            </Checkbox>
          </Form.Item>
          <Button
            size="small"
            type="button"
            onClick={() => onDelete(form.getFieldValue('products'))}
          >
            선택 삭제
          </Button>
        </Space>

        <Form.Array name="products">
          {(fields) =>
            fields.map(({ index }) => {
              const productData = form.getFieldValue('products');
              return (
                <S.ProductListWrap>
                  <Form.Item
                    name={[index, 'check']}
                    style={{ display: 'flex' }}
                  >
                    <Checkbox.Group>
                      <Checkbox
                        key={index}
                        value="true"
                        style={{ paddingRight: 0 }}
                      />
                    </Checkbox.Group>
                  </Form.Item>
                  <S.ProductList>
                    <img src={productData[index].imageUrl} alt="" />
                    <h2>{productData[index].name}</h2>
                    <h3>{productData[index].point.toLocaleString()}원</h3>
                    <Form.Item name={[index, 'quantity']}>
                      <Stepper
                        min={1}
                        defaultValue={productData[index].quantity}
                      />
                    </Form.Item>
                  </S.ProductList>
                </S.ProductListWrap>
              );
            })
          }
        </Form.Array>
      </Form>
      <S.TotalWrap>
        <span>
          <strong>총 결제 예정 금액</strong> ( 총 : {total.quantity}건 )
        </span>
        <span>{total.point.toLocaleString()}원</span>
      </S.TotalWrap>
    </>
  );
};
export default CartList;
