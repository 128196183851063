import React, {useEffect} from 'react';
import {Toast} from 'antd-mobile';
import {usePostKakaoLogin} from "services/queries/user/hooks/usePostUser";
import {useCookies} from "react-cookie";
import {setAxiosHeader} from 'services/api-config';
import {queryClient} from 'services/queryClient';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAlert} from "store/alertStore";
import axios from "axios";

const KakoLogin = () => {
    const [cookie, setCookie, removeCookie] = useCookies([
        'logged_noAuth',
        'logged_auth',
        'firetoken',
    ]);
    const {showAlert} = useAlert();
    const navigator = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {data: user, mutate: loginAction} = usePostKakaoLogin();

    useEffect(() => {
        const kakaoCode = searchParams.get("code") ?? ''
        if (kakaoCode === '') {
            // redirect to login page
            navigator('/login');
        }
        axios.post(`https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_REST}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&code=${kakaoCode}`, {
            headers: {
                "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
        })
        .then((res) => {
            loginAction(
                {
                    code: res.data.access_token,
                },
                {
                    onError: () => {
                        showAlert(
                            {
                                title: '안내',
                                msg: '관리자에게 문의해 주세요.',
                                btnNm: '확인',
                            },
                            () => {
                                navigator('/login');
                            },
                        );
                    },
                },
            );
        });
    }, []);

    useEffect(() => {
        if (user !== undefined) {
            const { accessToken } = user;
            setCookie('logged_auth', user, {
                path: '/',
                expires: new Date(Date.now() + user.accessTokenExpire),
            });
            removeCookie('logged_noAuth', { path: '/' });
            setAxiosHeader('authorization', `Bearer ${accessToken}`);

            Toast.show({
                content: '로그인 되었습니다.',
                position: 'bottom',
            });
            queryClient.clear();
            navigator('/main');
        }
    }, [user]);

    return (
        <>
        </>
    );
};

export default KakoLogin;
