import 'styles/globals.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'moment/locale/ko';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import MainPage from 'pages/main';
import LikePage from 'pages/like';
import WrtieCardPage from 'pages/wrtie/card';
import CartPage from 'pages/cart';
import Mypage from 'pages/mypage';
import SearchPage from 'pages/search';
import AlarmPage from 'pages/alarm';
import LoginPage from 'pages/login';
import { queryClient } from 'services/queryClient';
import KakaoLogin from 'pages/login/kakao';
import SignUpPage from 'pages/join';
import MyContentPage from 'pages/mypage/contnets';
import AlertModal from 'components/ui/common/modal/alertModal';
import ConfirmModal from 'components/ui/common/modal/confirmModal';
import { CookiesProvider, useCookies } from 'react-cookie';
import AuthMiddleware from 'middleware/AuthMiddleware';
import NoticePage from 'pages/mypage/notice';
import EventPage from 'pages/mypage/event';
import QnaPage from 'pages/mypage/qna';
import QnaWritePage from 'pages/mypage/qna/write';
import ContentPage from 'pages/content';
import QnaDetailPage from 'pages/mypage/qna/detail';
import UserOrderPage from 'pages/mypage/order';
import UserOrderDetailPage from 'pages/mypage/order/detail';
import UserSellPage from 'pages/mypage/sell';
import UserSellDetailPage from 'pages/mypage/sell/detail';
import UserPointHistoryPage from 'pages/mypage/point/history';
import UserPointChargePage from 'pages/mypage/point/charge';
import ErrorPage from 'pages/error';
import FaqPage from 'pages/mypage/faq';
import MyInfoPage from 'pages/mypage/info';
import { useEffect } from 'react';
import UserPointRewardPage from 'pages/mypage/point/reward';
import TermPage from 'pages/term';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AuthMiddleware>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="/main" />} />
              <Route path="/main" element={<MainPage />} />
              <Route path="/like" element={<LikePage />} />
              <Route path="/write/card" element={<WrtieCardPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/mypage" element={<Mypage />} />
              <Route path="/mypage/info" element={<MyInfoPage />} />
              <Route path="/mypage/contents" element={<MyContentPage />} />
              <Route path="/mypage/notice" element={<NoticePage />} />
              <Route path="/mypage/event" element={<EventPage />} />
              <Route path="/mypage/qna" element={<QnaPage />} />
              <Route path="/mypage/qna/:id" element={<QnaDetailPage />} />
              <Route path="/mypage/qna/write" element={<QnaWritePage />} />
              <Route path="/mypage/order" element={<UserOrderPage />} />
              <Route
                path="/mypage/order/:id"
                element={<UserOrderDetailPage />}
              />
              <Route path="/mypage/sell" element={<UserSellPage />} />
              <Route path="/mypage/sell/:id" element={<UserSellDetailPage />} />
              <Route
                path="/mypage/point/history"
                element={<UserPointHistoryPage />}
              />
              <Route
                path="/mypage/point/charge"
                element={<UserPointChargePage />}
              />
              <Route
                path="/mypage/point/reward"
                element={<UserPointRewardPage />}
              />
              <Route path="/mypage/faq" element={<FaqPage />} />
              <Route path="/content/:id" element={<ContentPage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/alarm" element={<AlarmPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signUp" element={<SignUpPage />} />
              <Route path="/oauth/callback/kakao" element={<KakaoLogin />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/term" element={<TermPage />} />
            </Routes>
            <AlertModal />
            <ConfirmModal />
          </BrowserRouter>
        </AuthMiddleware>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
