import styled from 'styled-components';

const Wrapper = styled.div`
  flex-flow: row wrap;
  -webkit-box-orient: horizontal;
  width: 100%;
  padding: 0px 0.5rem;
  margin-top: 10px;
  display: flex;
  ul {
    padding: 0;
  }
  li:first-child {
    margin-top: 0;
  }
  li {
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
`;

export { Wrapper };
