import axios from 'axios';
import mem from 'mem';
import { Cookies } from 'react-cookie';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const EndPoints = {
  cart: 'cart',
  content: 'content',
  event: 'event',
  faq: 'faq',
  layout: 'layout',
  like: 'like',
  notice: 'notice',
  order: 'order',
  qna: 'qna',
  search: 'search',
  user: 'user',
  point: 'point',
};

const setAxiosHeader = (key: string, value: string) => {
  api.defaults.headers[key] = value;
};

export const refreshAccessTokenFn = mem(
  async () => {
    const cookies = new Cookies();
    const refresh = cookies.get('logged_noAuth') || cookies.get('logged_auth');
    const isLogin = cookies.get('logged_auth') !== undefined;
    const response = await api.post('user/refresh', {
      refreshToken: refresh.refreshToken,
      isLogin,
    });

    if (response) {
      if (cookies.get('logged_noAuth'))
        cookies.set('logged_noAuth', response.data, {
          path: '/',
        });
      if (cookies.get('logged_auth'))
        cookies.set('logged_auth', response.data, {
          path: '/',
        });
    }

    return response.data;
  },
  { maxAge: 1000 },
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const cookies = new Cookies();

    if (error.response.status === 403) {
      const data = await refreshAccessTokenFn();
      setAxiosHeader('authorization', `Bearer ${data.accessToken}`);
      originalRequest.headers.authorization = `Bearer ${data.accessToken}`;
      return api(originalRequest);
    }

    if (error.response.data.detail === 'RefreshToken is not valid') {
      await cookies.remove('logged_auth', { path: '/' });

      const response = await api.post('user/login', {
        loginId: '',
        password: '',
        firebaseToken: cookies.get('firetoken'),
      });
      if (response) {
        cookies.set('logged_noAuth', response.data, {
          path: '/',
        });
        setAxiosHeader('authorization', `Bearer ${response.data.accessToken}`);
      }
      window.history.replaceState({}, '', '/error');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
    return Promise.reject(error);
  },
);

export { api, EndPoints, setAxiosHeader };
