// import { Link } from 'react-router-dom';
import { Avatar, Button, List, Popover } from 'antd-mobile';
import {
  BillOutline,
  CheckShieldOutline,
  DownCircleOutline,
  EditSOutline,
  ExclamationTriangleOutline,
  FileOutline,
  HandPayCircleOutline,
  InformationCircleOutline,
  MessageOutline,
  PayCircleOutline,
  ReceiptOutline,
  ReceivePaymentOutline,
  SetOutline,
  StarOutline,
} from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';
import * as S from './styled';

interface MypageProps {
  nickname: string;
  mobile: string;
  state: 'good' | 'soso' | 'bad';
  logout: () => void;
}

const Mypage = ({ nickname, mobile, state, logout }: MypageProps) => {
  const navigator = useNavigate();
  return (
    <>
      <List>
        <List.Item prefix={<Avatar src="" />} description={mobile}>
          {nickname}
          <SetOutline
            style={{ marginLeft: 5 }}
            onClick={() => navigator('/mypage/info')}
          />
        </List.Item>
        <List.Item>
          <Button block color="primary" size="large" onClick={logout}>
            로그아웃
          </Button>
        </List.Item>
      </List>
      <List mode="card" header="내 정보">
        <List.Item
          prefix={<CheckShieldOutline />}
          extra={
            <S.StateWrapper>
              <S.State type={state}>
                <DownCircleOutline />
                {state === 'good' && '양호'}
                {state === 'soso' && '주의'}
                {state === 'bad' && '제한'}
              </S.State>
              <Popover
                content={
                  <div style={{ fontSize: 12 }}>
                    양호 : 경고사항 없음 <br />
                    준제한 : 신고 1 ~ 3회 <br />
                    제한 : 신고 4 ~ 6회 <br />
                    금지 : 6회 초과 계정 블락 <br />
                  </div>
                }
                trigger="click"
                placement="top"
              >
                <InformationCircleOutline
                  style={{ marginLeft: 6, fontSize: 14 }}
                />
              </Popover>
            </S.StateWrapper>
          }
        >
          내 상태
        </List.Item>
        <List.Item
          prefix={<EditSOutline />}
          onClick={() => {
            navigator('/mypage/contents');
          }}
        >
          작성글
        </List.Item>
        <List.Item
          prefix={<ReceivePaymentOutline />}
          onClick={() => {
            navigator('/mypage/order');
          }}
        >
          구매 내역
        </List.Item>
        <List.Item
          prefix={<ReceiptOutline />}
          onClick={() => {
            navigator('/mypage/sell');
          }}
        >
          판매 내역
        </List.Item>
      </List>
      <List mode="card" header="포인트">
        <List.Item
          prefix={<BillOutline />}
          onClick={() => {
            navigator('/mypage/point/history');
          }}
        >
          포인트 내역
        </List.Item>
        <List.Item
          prefix={<PayCircleOutline />}
          onClick={() => {
            navigator('/mypage/point/charge');
          }}
        >
          포인트 충전
        </List.Item>
        <List.Item
          prefix={<HandPayCircleOutline />}
          onClick={() => {
            navigator('/mypage/point/reward');
          }}
        >
          포인트 리워드
        </List.Item>
      </List>
      <List mode="card" header="이용">
        <List.Item
          prefix={<ExclamationTriangleOutline />}
          onClick={() => {
            navigator('/mypage/notice');
          }}
        >
          공지사항
        </List.Item>
        <List.Item
          prefix={<StarOutline />}
          onClick={() => {
            navigator('/mypage/event');
          }}
        >
          이벤트
        </List.Item>
        <List.Item
          prefix={<FileOutline />}
          onClick={() => {
            navigator('/mypage/faq');
          }}
        >
          자주 묻는 질문
        </List.Item>
        <List.Item
          prefix={<MessageOutline />}
          onClick={() => {
            navigator('/mypage/qna');
          }}
        >
          1:1 문의
        </List.Item>
      </List>
    </>
  );
};

export default Mypage;
