import Bottom from '../bottom';

const Footer = () => (
  <>
    <footer id="footer" className="footer">
      <div className="inner">
        <div className="footTop">
          <a href='http://pf.kakao.com/_xjmjVG/chat' target='_blank' rel='noreferrer'>
            고객센터
          </a>
        </div>
        <div className="footBottom">
          <div className="agreeWrap">
            <ul>
                <li><a href="/term">이용약관</a></li>
                <li><a href="#void">개인정보처리방침</a></li>
            </ul>
          </div>
          <p className="small mt20">
            바나나만두는 통신판매중개자로서 상품의 등록정보, 판매,
            문의답변에 대한 의무와 책임은 각 판매자에게 있습니다.
          </p>
          <p className="small mt20">© Bananamandu (537-77-00372)</p>
        </div>
      </div>
    </footer>
    <Bottom />
  </>
);
export default Footer;
