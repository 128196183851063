export const getState = (idx: string) => {
  if (Number(idx) === 0) {
    return 'good';
  }

  if (Number(idx) >= 1 && Number(idx) <= 3) {
    return 'soso';
  }

  return 'bad';
};
