import React from 'react';
import SearchHeader from 'components/ui/layouts/header/search';
import * as S from '../styled';

interface SearchLayoutProps {
  children: React.ReactNode;
  onSearch: (value: string) => void;
  searchValue: string;
}

const SearchLayout = ({
  children,
  onSearch,
  searchValue,
}: SearchLayoutProps) => (
  <>
    <SearchHeader onSearch={onSearch} value={searchValue} />
    <S.Wrapper type="main">{children}</S.Wrapper>
  </>
);

export default SearchLayout;
