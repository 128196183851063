import React, { useEffect } from 'react';

import { Modal } from 'antd-mobile';
import { useAlert } from 'store/alertStore';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

const AlertModal = () => {
  const { open, title, msg, btnNm, closeAlert, callbackAction } = useAlert();
  const { pathname } = useLocation();

  useEffect(() => {
    closeAlert();
  }, [pathname]);

  return (
    <Modal
      visible={open}
      title={title}
      content={<div style={{ textAlign: 'center' }}>{parse(msg)}</div>}
      closeOnAction
      onClose={() => {
        if (callbackAction !== undefined) callbackAction();
        closeAlert();
      }}
      actions={[
        {
          key: 'confirm',
          text: btnNm,
        },
      ]}
    />
  );
};

export default AlertModal;
