import Container from 'components/ui/layouts/container';
// import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useFetchQnaDetail } from 'services/queries/qna/hooks/useFetchQna';
import QnaDetail from 'components/domain/mypage/qna/detail';

const QnaDetailPage = () => {
  const { id } = useParams();
  const { data: qna } = useFetchQnaDetail({ qna_idx: id });

  return (
    <Container type="sub" title="1:1 문의 상세">
      {qna && <QnaDetail data={qna} />}
    </Container>
  );
};

export default QnaDetailPage;
