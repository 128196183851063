import styled from 'styled-components';

const ProductListWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductList = styled.div`
  position: relative;
  padding: 12px;
  padding-left: 114px;
  min-height: 114px;
  line-height: 1.2;
  border-bottom: 1px solid #eeeeee;
  background: #fff;
  width: 100%;
  h2 {
    font-size: 14px;
    margin-bottom: 4px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  > span {
    color: #9c9c9c;
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
  }
  img {
    position: absolute;
    left: 12px;
    top: 12px;
    width: 90px;
    height: 90px;
  }
  button {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: calc(100% - 126px);
  }
`;

export { ProductListWrap, ProductList };
