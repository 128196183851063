import { Button, CheckList, Grid, Popup, SearchBar } from 'antd-mobile';
import { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { InfiniteData } from 'react-query';
import { SearchCardModel } from 'services/queries/search/search';
import * as S from './styled';

interface SelectFieldProps {
  visible: boolean;
  loadMore: () => void;
  hasMore?: boolean;
  setVisible: (vale: boolean) => void;
  onSearch: (value: any) => void;
  setContents: React.Dispatch<React.SetStateAction<any>>;
  filteredItems: InfiniteData<SearchCardModel[]> | undefined;
}

const FilterSearchModal: FC<SelectFieldProps> = ({
  visible,
  setVisible,
  onSearch,
  loadMore,
  setContents,
  hasMore,
  filteredItems,
}) => {
  const [checkList, setCheckList] = useState<string[]>([]);

  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      destroyOnClose
    >
      <S.Wrapper>
        <SearchBar placeholder="검색어를 입력해주세요" onSearch={onSearch} />
      </S.Wrapper>
      <S.CheckListActiveWrapper>
        {checkList.length === 0 ? (
          '등록할 컨텐츠를 선택해주세요'
        ) : (
          <CheckList
            multiple
            value={checkList}
            onChange={(val) => {
              setCheckList(val);
            }}
            defaultValue={checkList}
          >
            {checkList.map((item: any) => (
              <CheckList.Item
                key={JSON.parse(item).idx}
                value={JSON.stringify({
                  idx: JSON.parse(item).idx,
                  imageUrl: JSON.parse(item).imageUrl,
                  name: JSON.parse(item).name,
                  quantity: 1,
                })}
              >
                <S.CheckListItemWrapper>
                  <S.CheckListImgWrapper>
                    <img
                      src={JSON.parse(item).imageUrl}
                      title={JSON.parse(item).name}
                      alt="검색 리스트 이미지"
                      height="100%"
                    />
                  </S.CheckListImgWrapper>
                  <S.CheckListTextWrapper>
                    {JSON.parse(item).name}
                  </S.CheckListTextWrapper>
                </S.CheckListItemWrapper>
              </CheckList.Item>
            ))}
          </CheckList>
        )}
      </S.CheckListActiveWrapper>

      <S.CheckListWrapper>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} useWindow={false}>
          <CheckList
            multiple
            value={checkList}
            onChange={(val) => {
              setCheckList(val);
            }}
          >
            {filteredItems !== undefined &&
              filteredItems.pages.map((pageData) =>
                pageData.map((item: any) => (
                  <CheckList.Item
                    key={item.idx}
                    value={JSON.stringify({
                      idx: item.idx,
                      imageUrl: item.imageUrl,
                      name: item.name,
                      quantity: 1,
                    })}
                  >
                    <S.CheckListItemWrapper>
                      <S.CheckListImgWrapper>
                        <img
                          src={item.imageUrl}
                          title={item.name}
                          alt="검색 리스트 이미지"
                          height="100%"
                        />
                      </S.CheckListImgWrapper>
                      <S.CheckListTextWrapper>
                        {item.name}
                      </S.CheckListTextWrapper>
                    </S.CheckListItemWrapper>
                  </CheckList.Item>
                )),
              )}
          </CheckList>
        </InfiniteScroll>
      </S.CheckListWrapper>

      <S.BtnWrapper>
        <Grid columns={2} gap={8}>
          <Grid.Item>
            <Button
              block
              color="primary"
              size="large"
              fill="outline"
              onClick={() => {
                setVisible(false);
                onSearch(undefined);
              }}
            >
              취소
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              block
              color="primary"
              size="large"
              onClick={() => {
                const newList = checkList.map((item) => JSON.parse(item));
                setContents(newList);
                onSearch(undefined);
                setVisible(false);
              }}
            >
              확인
            </Button>
          </Grid.Item>
        </Grid>
      </S.BtnWrapper>
    </Popup>
  );
};

export default FilterSearchModal;
