import moment from 'moment';
import { InfiniteData } from 'react-query';
import { UserPointHistoryModel } from 'services/queries/user/user';
import NoList from 'components/ui/common/noList';
import * as S from './styled';

interface PointHistoryListProps {
  data: InfiniteData<UserPointHistoryModel[]> | undefined;
}

const PointHistoryList = ({ data }: PointHistoryListProps) =>
  data !== undefined && data.pages[0].length === 0 ? (
    <NoList title="포인트 내역이 없습니다." status="default" />
  ) : (
    <S.Wrapper>
      {data !== undefined &&
        data.pages.map((pageData) =>
          pageData.map((list) => (
            <S.PointList key={list.idx}>
              <S.PointListDate>
                {moment(list.createdAt).format('YYYY-MM-DD')}
              </S.PointListDate>
              <S.PointListText>
                <h3>{list.name}</h3>
                <span>{list.point.toLocaleString()}원</span>
              </S.PointListText>
            </S.PointList>
          )),
        )}
    </S.Wrapper>
  );

export default PointHistoryList;
