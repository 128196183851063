import styled from 'styled-components';
import { CheckList as Check } from 'antd-mobile';

const Wrapper = styled.div`
  padding: 10px;
  background: #ededed;
  position: fixed;
  width: 100%;
  top: 56px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
`;

const Airticle = styled.div`
  width: 100%;
  height: 49px;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 12px;
  border-bottom: 1px solid var(--adm-color-border);
`;

const CheckListWrapper = styled.div`
  height: 300px;
  overflow-y: scroll;
`;

const CheckList = styled(Check)`
  --border-top: 0;
  --border-bottom: 0;
`;

export { Wrapper, Airticle, CheckListWrapper, SearchBarWrapper, CheckList };
