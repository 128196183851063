import InfiniteScroll from 'react-infinite-scroller';

import Container from 'components/ui/layouts/container';
import { useNavigate } from 'react-router-dom';
import QnaList from 'components/domain/mypage/qna';
import { useFetchQna } from 'services/queries/qna/hooks/useFetchQna';

const QnaPage = () => {
  const navigator = useNavigate();
  const {
    data: event,
    fetchNextPage,
    hasNextPage,
  } = useFetchQna({ page: 1, pageSize: 10 });

  const onClickList = (idx: number) => {
    navigator(`/mypage/qna/${idx}`);
  };

  const onClickWrite = () => {
    navigator(`/mypage/qna/write`);
  };

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  return (
    <Container type="sub" title="1:1 문의">
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <QnaList
          list={event}
          onClick={onClickList}
          onClickWrite={onClickWrite}
        />
      </InfiniteScroll>
    </Container>
  );
};

export default QnaPage;
