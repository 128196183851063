import { api, EndPoints } from 'services/api-config';
import { useMutation } from 'react-query';
import {LoginBody, JoinBody, InfoBody, LoginKakaoBody} from '../user';

export const usePostLogin = () =>
  useMutation(async (form: LoginBody) => {
    const { data } = await api.post(`${EndPoints.user}/login`, form);
    return data;
  });

export const usePostJoin = () =>
  useMutation(async (form: JoinBody) => {
    const { data } = await api.post(`${EndPoints.user}/join`, form);
    return data;
  });

export const usePostKakaoLogin = () =>
    useMutation(async (form: LoginKakaoBody) => {
        const { data } = await api.post(`${EndPoints.user}/login/kakao`, form);
        return data;
    });

export const usePutInfo = () =>
  useMutation(async (form: InfoBody) => {
    const { data } = await api.put(`${EndPoints.user}/me`, form);
    return data;
  });
