import { Toast } from 'antd-mobile';
import Contnet from 'components/domain/content';
import Container from 'components/ui/layouts/container';
import useAccess from 'hooks/useAccess';
import { useNavigate, useParams } from 'react-router-dom';
import { CartAddBody } from 'services/queries/cart/cart';
import { usePostCart } from 'services/queries/cart/hooks/usePostCart';
import { useFetchContentDetail } from 'services/queries/content/hooks/useFetchContent';
import { usePostLike } from 'services/queries/like/hooks/usePostLike';
import { LikeBody } from 'services/queries/like/like';
import { usePostOrder } from 'services/queries/order/hooks/usePostOrder';
import { useAlert } from 'store/alertStore';
import { useConfirm } from 'store/confirmStore';

const ContentPage = () => {
  const { id } = useParams();
  const navigator = useNavigate();
  const { data: content } = useFetchContentDetail({ content_idx: Number(id) });
  const { mutate: actionOrder } = usePostOrder();
  const { mutate: actionLike } = usePostLike('detail');
  const { mutate: actionCart } = usePostCart();
  const { accessCheck } = useAccess({ isAuth: true });
  const { showConfirm } = useConfirm();
  const { showAlert } = useAlert();

  const onFinish = (val: any) => {
    const form = val.products
      .filter((item: any) => item.count !== undefined && item.count !== 0)
      .map((item: any) => ({ idx: item.idx, quantity: item.count }));

    if (form.length === 0) {
      showAlert({
        msg: '선택한 상품이 없습니다.',
        title: '안내',
        btnNm: '확인',
      });
      return;
    }

    actionOrder(form, {
      onSuccess: () => {
        showAlert(
          {
            msg: '구매가 완료 되었습니다.',
            title: '안내',
            btnNm: '확인',
          },
          () => {
            navigator('/mypage/order');
          },
        );
      },
      onError: (err: any) => {
        if (err.response.data.detail === 'point is not valid') {
          showConfirm(
            {
              msg: '소지금이 부족합니다.<br>충전페이지로 이동하시겠습니까?',
              title: '안내',
              cancelBtnNm: '취소',
              confirmBtnMn: '확인',
            },
            () => {
              navigator('/mypage/point/charge');
            },
          );
        }
      },
    });
  };

  const onLike = (form: LikeBody, isLike: boolean) => {
    Toast.show({
      content: isLike
        ? '좋아요가 취소 되었습니다.'
        : '좋아요가 추가 되었습니다.',
      position: 'bottom',
    });
    actionLike(form);
  };

  const onCart = (form: CartAddBody[]) => {
    if (form.length === 0) {
      showAlert({
        msg: '선택한 상품이 없습니다.',
        title: '안내',
        btnNm: '확인',
      });
      return;
    }
    actionCart(form, {
      onSuccess: () => {
        showConfirm(
          {
            msg: '장바구니에 담겼습니다.<br/> 장바구니로 이동하겠습니까?',
            title: '안내',
            cancelBtnNm: '취소',
            confirmBtnMn: '확인',
          },
          () => {
            navigator('/cart');
          },
        );
      },
    });
  };

  return (
    <Container title="상품 정보" type="content">
      {content && id && (
        <Contnet
          data={content}
          idx={id}
          onLike={onLike}
          onCart={onCart}
          onFinish={onFinish}
        />
      )}
    </Container>
  );
};

export default ContentPage;
