import { Button, Form, Input, Stepper } from 'antd-mobile';
import { AddCircleOutline, SearchOutline } from 'antd-mobile-icons';
import ImgUploader from 'components/ui/common/imgUploader';
import FilterModal from 'components/ui/common/modal/filterModal';
import { useEffect } from 'react';
import { LayoutCateModel } from 'services/queries/layout/layout';
import { useAlert } from 'store/alertStore';
import * as S from './styled';

interface WriteCardProps {
  onFinish: (value: any) => void;
  openSearch: (vale: boolean) => void;
  setCategory: (vale: string) => void;
  contents: any[];
  setContents: React.Dispatch<React.SetStateAction<any>>;
  categoryList: LayoutCateModel[];
}

const WriteCard = ({
  onFinish,
  openSearch,
  setCategory,
  setContents,
  contents,
  categoryList,
}: WriteCardProps) => {
  const [form] = Form.useForm();
  const { showAlert } = useAlert();

  useEffect(() => {
    if (contents.length > 0) {
      const contentsform = form.getFieldValue('contents');
      form.setFieldValue('contents', [...contentsform, ...contents]);
      setContents([]);
    }
  }, [contents]);

  return (
    <Form
      form={form}
      initialValues={{
        contents: [],
      }}
      onValuesChange={(changedValues) => {
        if (changedValues.category !== undefined) {
          setCategory(changedValues.category);
          form.setFieldValue('contents', []);
        }
      }}
      onFinish={onFinish}
      footer={
        <Button block type="submit" color="primary" size="large">
          등록
        </Button>
      }
      mode="card"
    >
      <Form.Item
        name="category"
        label="카테고리"
        rules={[{ required: true, message: '카테고리를 선택해주세요' }]}
      >
        <FilterModal
          filteredItems={categoryList}
          placeholder="카테고리를 선택해주세요"
        />
      </Form.Item>

      <Form.Item
        name="title"
        label="제목"
        rules={[{ required: true, message: '제목을 입력해주세요' }]}
      >
        <Input placeholder="제목을 입력하세요" />
      </Form.Item>

      <Form.Item
        name="images"
        label="이미지"
        rules={[{ required: true, message: '이미지를 등록해주세요' }]}
      >
        <ImgUploader />
      </Form.Item>
      <Form.Header />
      <S.AddButton
        onClick={() => {
          if (form.getFieldValue('category') === undefined) {
            showAlert({
              msg: '카테고리를 선택해주세요',
              title: '안내',
              btnNm: '확인',
            });
            return;
          }

          openSearch(true);
        }}
      >
        <span>
          <SearchOutline /> 카드 검색
        </span>
      </S.AddButton>

      <Form.Array
        name="contents"
        onAdd={(operation) => operation.add()}
        renderAdd={() => (
          <span>
            <AddCircleOutline /> 카드 추가
          </span>
        )}
        renderHeader={({ index }, { remove }) => (
          <>
            <span>카드{index + 1}</span>
            <a onClick={() => remove(index)} style={{ float: 'right' }}>
              삭제
            </a>
          </>
        )}
      >
        {(fields) =>
          fields.map(({ index }) => (
            <>
              <Form.Item
                name={[index, 'name']}
                label="제목"
                rules={[{ required: true, message: '타이틀을 입력해주세요' }]}
              >
                <Input placeholder="제목을 입력하세요" />
              </Form.Item>
              <Form.Item
                name={[index, 'point']}
                label="가격"
                rules={[{ required: true, message: '가격을 입력해주세요' }]}
              >
                <Input type="number" placeholder="가격을 입력하세요" />
              </Form.Item>
              <Form.Item name={[index, 'quantity']} label="카드 수량">
                <Stepper min={1} defaultValue={1} />
              </Form.Item>
              {form.getFieldValue('contents')[index] !== undefined &&
              form.getFieldValue('contents')[index].imageUrl !== undefined ? (
                <Form.Item name={[index, 'imageUrl']} label="이미지">
                  <S.WriteImgWrapper>
                    <img
                      src={form.getFieldValue('contents')[index].imageUrl}
                      alt={`카드${index} 이미지`}
                    />
                  </S.WriteImgWrapper>
                </Form.Item>
              ) : (
                <Form.Item
                  name={[index, 'image']}
                  label="이미지"
                  rules={[{ required: true, message: '이미지를 등록해주세요' }]}
                >
                  <ImgUploader />
                </Form.Item>
              )}
            </>
          ))
        }
      </Form.Array>
    </Form>
  );
};

export default WriteCard;
