import create from 'zustand';

interface State {
  open: boolean;
  title: string;
  msg: string;
  cancelAction?: () => void;
  confirmAction?: () => void;
  confirmBtnMn: string;
  cancelBtnNm: string;
}

interface Confirm {
  title: string;
  msg: string;
  confirmBtnMn: string;
  cancelBtnNm: string;
}

interface Action {
  showConfirm: (
    data: Confirm,
    confirmAction?: () => void,
    cancelAction?: () => void,
  ) => void;
  closeConfirm: () => void;
}

const initialState: State = {
  open: false,
  title: '',
  msg: '',
  confirmAction: undefined,
  cancelAction: undefined,
  confirmBtnMn: '확인',
  cancelBtnNm: '취소',
};

export const useConfirm = create<State & Action>()((set) => ({
  ...initialState,
  showConfirm: (data, confirmAction, cancelAction) => {
    set(() => ({
      ...initialState,
      ...data,
      open: true,
      confirmAction,
      cancelAction,
    }));
  },
  closeConfirm: () => {
    set(() => ({
      open: false,
      confirmAction: undefined,
      cancelAction: undefined,
    }));
  },
}));
