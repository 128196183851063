import InfiniteScroll from 'react-infinite-scroller';
import FaqMenu from 'components/domain/mypage/faq/menu';
import Container from 'components/ui/layouts/container';
import { useState } from 'react';
import {
  useFetchFaq,
  useFetchFaqMenu,
} from 'services/queries/faq/hooks/useFetchFaq';
import FaqList from 'components/domain/mypage/faq/list';
// import { useNavigate } from 'react-router-dom';
// import QnaList from 'components/domain/mypage/qna';
// import { useFetchQna } from 'services/queries/qna/hooks/useFetchQna';

const QnaPage = () => {
  const [tabIdx, setTabIdx] = useState('1');
  const { data: tabMenu } = useFetchFaqMenu();
  const {
    data: faq,
    fetchNextPage,
    hasNextPage,
  } = useFetchFaq({ page: 1, pageSize: 10, classification: tabIdx });

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  return (
    <Container type="sub" title="자주 묻는 질문">
      {tabMenu && (
        <FaqMenu list={tabMenu} setTabIdx={setTabIdx} activeIdx={tabIdx} />
      )}
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <FaqList list={faq} />
      </InfiniteScroll>
    </Container>
  );
};

export default QnaPage;
