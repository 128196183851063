import MyCotnentList from 'components/domain/mypage/content/list';
import Container from 'components/ui/layouts/container';
import InfiniteScroll from 'react-infinite-scroller';
import { useFetchUserContnet } from 'services/queries/user/hooks/useFetchUser';

const MyContentPage = () => {
  const {
    data: contents,
    fetchNextPage,
    hasNextPage,
  } = useFetchUserContnet({
    page: 1,
    pageSize: 10,
  });

  const InfinitiLoad = () => {
    fetchNextPage({
      cancelRefetch: false,
    });
  };

  return (
    <Container type="sub" title="작성 글">
      <InfiniteScroll loadMore={InfinitiLoad} hasMore={hasNextPage}>
        <MyCotnentList data={contents} />
      </InfiniteScroll>
    </Container>
  );
};

export default MyContentPage;
