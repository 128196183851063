import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from 'store/confirmStore';

interface UseAccessProps {
  isAuth: boolean;
}

const useAccess = ({ isAuth }: UseAccessProps) => {
  const { showConfirm } = useConfirm();
  const navigate = useNavigate();
  const [cookies] = useCookies();

  const confirmAction = () => {
    navigate('/login');
  };

  const cancelAction = () => {
    navigate('/main');
  };

  const getToken = () =>
    cookies.logged_auth ? cookies.logged_auth.accessToken : null;

  const accessCheck = (disableRoute: boolean) => {
    if (isAuth) {
      if (!cookies.logged_auth) {
        showConfirm(
          {
            msg: '로그인이 필요한 서비스 입니다.<br/> 로그인 하시겠습니까?',
            title: '안내',
            cancelBtnNm: '취소',
            confirmBtnMn: '로그인',
          },
          confirmAction,
          disableRoute === false ? cancelAction : undefined,
        );
        return { auth: false };
      }
      return { auth: true };
    }
    return { auth: false };
  };

  return {
    accessCheck,
    getToken,
  };
};

export default useAccess;
