import { Toast } from 'antd-mobile';
import CartList from 'components/domain/cart';
import Container from 'components/ui/layouts/container';
import { useNavigate } from 'react-router-dom';
import { useDeleteCart } from 'services/queries/cart/hooks/useDeleteCart';
import { useFetchCart } from 'services/queries/cart/hooks/useFetchCart';
import { usePostOrder } from 'services/queries/order/hooks/usePostOrder';
import { useAlert } from 'store/alertStore';
import { useConfirm } from 'store/confirmStore';

const CartPage = () => {
  const { data: cart } = useFetchCart();
  const navigator = useNavigate();
  const { mutate: actionOrder } = usePostOrder();
  const { mutate: deleteCart } = useDeleteCart();
  const { showAlert } = useAlert();
  const { showConfirm } = useConfirm();

  const onDelete = (val: any) => {
    const checkValue = val.filter(
      (item: any) => item.check !== undefined && item.check[0] === 'true',
    );

    if (checkValue.length === 0) {
      showAlert({
        msg: '선택한 상품이 없습니다.',
        title: '안내',
        btnNm: '확인',
      });

      return;
    }

    showConfirm(
      {
        msg: '선택한 상품을 삭제하시겠습니까?',
        title: '안내',
        cancelBtnNm: '취소',
        confirmBtnMn: '확인',
      },
      () => {
        const form = checkValue.map((item: any) => ({ idx: item.idx }));

        form.map(async (item: any) => {
          await deleteCart({ cart_idx: item.idx });
        });

        Toast.show({
          content: '장바구니가 삭제 되었습니다.',
          position: 'bottom',
        });
      },
    );
  };

  const onFinish = (val: any) => {
    const form = val.products
      .filter(
        (item: any) => item.check !== undefined && item.check[0] === 'true',
      )
      .map((item: any) => ({ idx: item.productIdx, quantity: item.quantity }));

    if (form.length === 0) {
      showAlert({
        msg: '선택한 상품이 없습니다.',
        title: '안내',
        btnNm: '확인',
      });
      return;
    }

    actionOrder(form, {
      onSuccess: () => {
        showAlert(
          {
            msg: '구매가 완료 되었습니다.',
            title: '안내',
            btnNm: '확인',
          },
          () => {
            navigator('/mypage/order');
          },
        );
      },
      onError: (err: any) => {
        if (err.response.data.detail === 'point is not valid') {
          showConfirm(
            {
              msg: '소지금이 부족합니다.<br>충전페이지로 이동하시겠습니까?',
              title: '안내',
              cancelBtnNm: '취소',
              confirmBtnMn: '확인',
            },
            () => {
              navigator('/mypage/point/charge');
            },
          );
        }
      },
    });
  };
  return (
    <Container title="장바구니" type="sub">
      {cart && <CartList data={cart} onFinish={onFinish} onDelete={onDelete} />}
    </Container>
  );
};

export default CartPage;
